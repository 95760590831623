import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { ConversationListItem } from 'components';
import { getOtherChannels } from 'services';
import { chatTypes } from 'enums';
import { debounce } from 'lodash-es';

const PAGE_SIZE = 20;

const MyChannelsList = (props) => {
  const { searchText, selected, onSelect } = props;
  const { getText } = useTranslations();
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [allChannels, setAllChannels] = useState([]);

  useEffect(() => {
    getChannels({ query: searchText, offset: 0 });
  }, [searchText]);

  const getChannels = async (options) => {
    const body = {
      ...options,
      limit: PAGE_SIZE,
    };

    setLoading(true);
    getOtherChannels(body, (response) => {
      if (!response.success && response.message) return setLoading(false);

      if (response.length < PAGE_SIZE) setHasMore(false);
      const mappedChannels = response.map((ch) => ({ ...ch, targetType: chatTypes.channel }));
      options.offset === 0 ? setAllChannels(mappedChannels) : setAllChannels((prev) => [...prev, ...mappedChannels]);
      setLoading(false);
    });
  };

  const loadMoreChannels = () => {
    const newPage = page + 1;
    getChannels({ query: searchText, offset: newPage * PAGE_SIZE });
    setPage(newPage);
  };

  const handleUserScroll = debounce((e) => {
    const { scrollTop, scrollHeight } = e.target;

    if (allChannels?.length >= PAGE_SIZE && hasMore && !loading && scrollHeight - scrollTop < 800) loadMoreChannels();
  }, 200);

  const handleSelect = (id) => {
    if (!id) return;
    const foundChannel = allChannels?.find((channel) => channel.id === id);
    if (!foundChannel) return;

    onSelect(foundChannel);
  };

  return allChannels?.length ? (
    <ul onScroll={handleUserScroll}>
      {allChannels.map((channel) => (
        <ConversationListItem
          noMessage
          isMember={false}
          key={channel.id}
          chat={channel}
          isSelected={selected === channel.id}
          onChatOpen={handleSelect}
        />
      ))}
    </ul>
  ) : (
    !loading && <div className="no-data">{getText('noChannelsFound')}</div>
  );
};

MyChannelsList.propTypes = {
  searchText: PropTypes.string,
  selected: PropTypes.string,
  onSelect: PropTypes.func,
};

export default MyChannelsList;
