/* eslint-disable no-console */
import { showError } from 'components';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

// Firebase config
const firebaseConfig = {
  apiKey: pushNotifications.apiKey,
  authDomain: pushNotifications.authDomain,
  projectId: pushNotifications.projectId,
  storageBucket: pushNotifications.storageBucket,
  messagingSenderId: pushNotifications.messagingSenderId,
  appId: pushNotifications.appId,
  measurementId: pushNotifications.measurementId,
};

const vapidKey = pushNotifications.vapidKey;

export let fcmToken = null;

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service
export const messaging = getMessaging(firebaseApp);

export const manageServiceWorker = async (setFcmToken) => {
  const sw = await checkForServiceWorker();
  if (!sw) await registerServiceWorker(setFcmToken);
  else await attemptGetFcmToken(setFcmToken);
};

const checkForServiceWorker = async () => {
  if ('serviceWorker' in navigator) {
    const registrations = await navigator.serviceWorker.getRegistrations();
    const serviceWorker = registrations.find(
      (registration) => registration.active && registration.active.scriptURL.includes('/firebase-messaging-sw.js'),
    );
    return serviceWorker;
  }
};

// Service worker is not registered and permission = "granted"
const registerServiceWorker = async (setFcmToken) => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js');
      await navigator.serviceWorker.ready;

      const sw = registration.installing || registration.waiting || registration.active;
      if (sw) {
        if (sw.state === 'activated') attemptGetFcmToken(setFcmToken);
        else {
          sw.addEventListener('statechange', async (event) => {
            if (event.target.state === 'activated') attemptGetFcmToken(setFcmToken);
          });
        }
      } else attemptGetFcmToken(setFcmToken);
      // handleShowNotification(registration);
    } catch (error) {
      console.error('Error checking or registering service worker:', error);
    }
  }
};

const attemptGetFcmToken = async (setFcmToken, retries = 3, delay = 1000) => {
  while (retries > 0) {
    try {
      fcmToken = await getToken(messaging, { vapidKey });
      setFcmToken(fcmToken);

      return fcmToken;
    } catch (error) {
      if (error.name === 'AbortError') {
        console.warn(`Retrying FCM token retrieval. Retries left: ${retries - 1}`);
        retries -= 1;
        await new Promise((resolve) => setTimeout(resolve, delay));
      } else {
        console.error('Error retrieving FCM token:', error);
        setFcmToken('');
        throw error; // Re-throw if it's not a retryable error
      }
    }
  }
  setFcmToken('');
  showError('Failed to retrieve FCM token after multiple retries.');
};

// const handleShowNotification = (reg) => {
//   const notificationOptions = {
//     body: 'Hi there',
//     icon: './assets/images/logo.svg',
//     badge: './assets/images/logo.svg',
//     // Should see how to open the app with given chat on click
//     actions: [
//       {
//         action: 'open',
//         title: 'Open chat',
//       },
//     ],
//     data: {
//       id: '019233e2-4145-7de3-a9a1-93612b2f5db1',
//       targetType: 'group',
//       origin: window.location.origin,
//     },
//   };

//   reg.showNotification('hi', notificationOptions);
// };
