import { getUserDisplayName } from 'utils';

export const USER_STATUS = {
  New: 'New',
  Pending: 'Pending',
  Active: 'Active',
  Frozen: 'Frozen',
  Terminated: 'Terminated',
};

export const USER_CHAT_STATUS = {
  Online: 'Online',
  Away: 'Away',
  DoNotDisturb: 'DoNotDisturb',
  Offline: 'Offline',
};

export const userStatusMap = {
  [USER_STATUS.New]: {
    color: 'blue',
  },
  [USER_STATUS.Pending]: {
    color: 'warning',
  },
  [USER_STATUS.Active]: {
    color: 'success',
  },
  [USER_STATUS.Frozen]: {
    color: 'error',
  },
  [USER_STATUS.Terminated]: {
    color: 'pink',
  },
};

export const userChatStatusMap = {
  [USER_CHAT_STATUS.Online]: {
    color: 'success',
  },
  [USER_CHAT_STATUS.Away]: {
    color: 'warning',
  },
  [USER_CHAT_STATUS.DoNotDisturb]: {
    color: 'gray',
  },
  // [USER_CHAT_STATUS.Offline]: {
  //   color: 'secondary',
  // },
};

export const userInfo = (user) => [
  {
    label: 'name',
    value: getUserDisplayName(user, false) ?? '-',
  },
  {
    label: 'username',
    value: user.username ?? '-',
  },
  {
    label: 'country',
    value: user?.country ?? '-',
  },
];