import { useState, createContext, useContext } from 'react';
import { themes, CACHE_KEYS } from 'enums';
import { getCacheVal, setCacheVal } from 'utils';

const ThemeContext = createContext({ theme: 'dark' });

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(getCacheVal(CACHE_KEYS.theme) ?? themes.dark);

  const toggleTheme = () => {
    const newTheme = theme === themes.dark ? themes.light : themes.dark;
    setTheme(newTheme);
    setCacheVal(CACHE_KEYS.theme, newTheme);
  };

  return <ThemeContext.Provider value={{ theme, toggleTheme }}>{children}</ThemeContext.Provider>;
};

export const useTheme = () => useContext(ThemeContext);
