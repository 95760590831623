export const tooltipContainer = (hideOnSmallScreen) => ({
  display: 'block',

  '.icon-container': {
    color: 'var(--secondary-weaker)',
    backgroundColor: 'transparent',
    padding: '0.8em 0.8em',
    margin: '0.6rem 1.2rem',
    position: 'relative',
    cursor: 'pointer',
    borderRadius: 10,
    marginBottom: '1rem',
    lineHeight: 1,

    '@media (max-width: 991px)': {
      padding: 0,
      margin: 0,
      fontSize: 22,
      display: hideOnSmallScreen ? 'none' : 'block'
    },
  },
});

export const tooltipContent = {
  '& .tooltip-content': {
    padding: '0.6em',
    backgroundColor: 'var(--gray-100)',
    color: 'var(--theme-color)',
    boxShadow: 'none',
    marginLeft: -16,
    borderRadius: 4,
    fontSize: 12,
    width: 120,
  },
};
