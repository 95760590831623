import { emitters } from 'enums/chat';
import { sendMessage } from './websocket';

// Send message to chat / group / channel
export const sendChatMessage = (data, callback) => sendMessage(emitters.sendMessage, data, callback);

// Search for messages across channels/groups/chats
export const searchInMessages = (data, callback) => sendMessage(emitters.search, data, callback);

// Add new direct chat when opened for the first time.
export const createDirectChat = (data, callback) => sendMessage(emitters.createChat, data, callback);

// Get messages after the last we have.
export const getMessages = (data, callback) => sendMessage(emitters.messageHistory, data, callback);

// Edit own message from chat/group/channel
export const editMessage = (data, callback) => sendMessage(emitters.editMessage, data, callback);

// Delete own message from chat/group/channel
export const deleteMessage = (data, callback) => sendMessage(emitters.deleteMessage, data, callback);

export const getMessageVersions = (data, callback) => sendMessage(emitters.messageVersions, data, callback);
