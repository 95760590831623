/* eslint-disable camelcase */
import { emitters } from 'enums/chat';
import { sendMessage } from './websocket';
import { postReq } from 'services/axios';

// Update user presence status
export const updatePresence = (status, callback) => sendMessage(emitters.updatePresence, { status }, callback);

export const searchUsers = async (searchText) => {
  const body = { query: searchText };
  const [res, err] = await postReq(`${apiUrls.peekcloakApiUrl}/search`, body);
  return [res, err];
};

export const getUsersData = (data) => postReq(`${apiUrls.peekcloakApiUrl}/cache`, data);
