import { useEffect, useState } from 'react';
import { useTranslations } from '@veraio/strank';
import {
  ChannelFormModal,
  Icon,
  SearchInput,
  showSuccess,
  MyChannelsList,
  OtherChannelsList,
  Button,
} from 'components';
import { getUserId, setCurrentChat, setCurrentChatById, useChatStore } from 'stores';
import { chatTypes } from 'enums';
import { createChannel } from 'services';
import { resetUnreadMessages } from 'stores/messages';
import { channelsContainer } from './styles';
import { hasAtLeastOneRole, USER_ROLES } from '@oneecosystem/authenticate';
import { debounce } from 'lodash-es';

const CHANNELS_TABS = {
  my: 'my',
  other: 'other',
};

const Channels = () => {
  const { getText } = useTranslations();
  const { currentChat } = useChatStore();
  const userId = getUserId();

  const [openChannelModal, setOpenChannelModal] = useState(false);
  const [openedChatId, setOpenedChatId] = useState(currentChat?.id);
  const [searchText, setSearchText] = useState();
  const [tab, setTab] = useState(CHANNELS_TABS.my);
  const isAdmin = hasAtLeastOneRole([USER_ROLES.CHAT_SUPER_ADMIN, USER_ROLES.CHAT_ADMIN]);

  useEffect(() => {
    setOpenedChatId(currentChat?.id);
  }, [currentChat?.id]);

  useEffect(() => {
    currentChat?.users?.find(user => user.id === userId) && setTab(CHANNELS_TABS.my);
  }, [currentChat?.users?.find(user => user.id === userId)]);

  const handleOpenChatById = (id) => {
    setCurrentChatById(id, chatTypes.channel);
    resetUnreadMessages(id);
  };

  const handleOpenChat = (chatData) => setCurrentChat({ ...chatData, targetType: chatTypes.channel });

  const handleOpenChannelModal = () => setOpenChannelModal(true);

  const handleCloseChannelModal = () => setOpenChannelModal(false);

  const handleCreateChannel = (data, resetForm) => {
    createChannel(data, (response) => {
      resetForm(!response.success);

      showSuccess('Successfully created a channel');
      handleCloseChannelModal();
    });
  };

  const handleSearch = debounce(setSearchText, 500);

  const handleChangeTab = (tabName) => {
    setTab(tabName);
    setSearchText('');
  };

  return (
    <>
      <div css={channelsContainer(isAdmin)}>
        <div className="page-title">
          <h4>{getText('channels')}</h4>
          {isAdmin && <Icon iconName="group_add" color="secondary" onClick={handleOpenChannelModal} />}
        </div>

        <SearchInput value={searchText} placeholder={getText('searchChannels')} onChange={handleSearch} />
        {isAdmin && (
          <div className="tabs">
            {Object.values(CHANNELS_TABS)?.map((tabName) => (
              <Button
                small
                key={tabName}
                type="secondary"
                onClick={() => handleChangeTab(tabName)}
                className={`tab-button ${tab === tabName && 'active'}`}>
                {getText(tabName)}
              </Button>
            ))}
          </div>
        )}
        <div className="channels-list">
          {tab === CHANNELS_TABS.my ? (
            <MyChannelsList searchText={searchText} selected={openedChatId} onSelect={handleOpenChatById} />
          ) : (
            tab === CHANNELS_TABS.other && (
              <OtherChannelsList searchText={searchText} selected={openedChatId} onSelect={handleOpenChat} />
            )
          )}
        </div>
      </div>

      {isAdmin && (
        <ChannelFormModal
          showModal={openChannelModal}
          onSubmit={handleCreateChannel}
          onClose={handleCloseChannelModal}
        />
      )}
    </>
  );
};

export default Channels;
