export const createChatContainer = [
  {
    '.chat-modal-container': {
      backgroundColor: 'var(--background-stronger)',
      '.modal-title': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 1.5rem 2rem 1.5rem',
        borderBottom: '1px solid var(--border-color)',
        color: 'var(--gray-100)',
      },

      '.modal-body': {
        padding: '1rem 2.5rem',
        '.members-container': {
          display: 'flex',
          flexDirection: 'column',

          label: {
            color: 'var(--secondary)',
          },

          p: {
            fontSize: 10,
          },

          '.members-button': {
            fontSize: 13,
            width: '12rem',
            minHeight: '3.2rem',
            margin: '1rem 0',
          },
        },
      },

      '.action-container': {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '2rem 1.5rem 0 1.5rem',
        borderTop: '1px solid var(--border-color)',
        '.close-button': {
          marginRight: 12,
          '&:hover': {
            textDecoration: 'underline',
          },
        },
      },
    },
  },
];
