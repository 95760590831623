export const chatActionsContainer = [
  {
    '.chat-actions-container': {
      '.search-container': {
        display: 'inline-block',
        position: 'relative',
      },
      '.action-icon': {
        margin: 'auto 10px',
        color: 'var(--secondary)',
        cursor: 'pointer',
        borderRadius: '8px',
        '&:hover': {
          color: 'var(--hover-backgorund)',
        },
      },
    },
  },
];
