import { parseUuid7ToDate, orderByTimestamp } from './moment';

export const getFirstSymbol = (val, def = 'C') => {
  if (!val) return def.toUpperCase();
  // Check if name starts with emoji
  const firstSymbolEmojiRegex = /^\p{RGI_Emoji}/gv;
  if (firstSymbolEmojiRegex.test(val)) return val.match(firstSymbolEmojiRegex)[0] ?? def.toUpperCase();

  return val[0]?.toUpperCase();
};

export const checkForNewDayTitle = (currentMessageId, prevMessageId) => {
  if (currentMessageId === prevMessageId) return true;

  const currentDate = parseUuid7ToDate(currentMessageId);
  const lastMessageDate = parseUuid7ToDate(prevMessageId);
  return (
    currentDate.getDate() !== lastMessageDate.getDate() ||
    currentDate.getMonth() !== lastMessageDate.getMonth() ||
    currentDate.getFullYear() !== lastMessageDate.getFullYear()
  );
};

export const checkForSenderData = (currentMessage, nextMessage) => {
  if (checkForNewDayTitle(nextMessage.messageId, currentMessage.messageId)) return true;
  if (!nextMessage || currentMessage?.author?.id !== nextMessage?.author?.id) return true;
  if (nextMessage?.deletedAt) return true;

  return false;
};

export const sortChannels = (channelsData = []) => {
  const sortedChannels = channelsData.sort((channelA, channelB) => {
    if (!channelA.public && channelB.public) return -1;
    if (channelA.public && !channelB.public) return 1;

    if (channelA.readOnly && !channelB.readOnly) return -1;
    if (!channelA.readOnly && channelB.readOnly) return 1;

    return orderByTimestamp(channelA.id, channelB.id);
  });

  return sortedChannels;
};
