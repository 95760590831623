import { decodeToken, getToken } from '@oneecosystem/authenticate';
import { useStore, createStore } from 'components';
import { USER_CHAT_STATUS } from 'enums';

const userStore = createStore({
  isAuthenticated: !!getToken(),
});

export const initializeUser = async () => {
  const token = getToken();
  if (!token) return;

  const decodedToken = decodeToken(token);

  const initialUser = {
    id: decodedToken.sub,
    email: decodedToken.email,
    firstName: decodedToken.given_name,
    lastName: decodedToken.family_name,
    username: decodedToken.preferred_username,
  };

  userStore.setState({
    ...initialUser,
    status: USER_CHAT_STATUS.Online,
    isAuthenticated: true,
  });
};

export const updateUserPresence = (status) => {
  userStore.setState((prev) => ({ ...prev, status }));
};

export const getUserId = () => {
  const storedId = userStore.getState()?.id;
  if (storedId) return storedId;

  const token = getToken();
  if (!token) return null;

  const decodedToken = decodeToken(token);
  return decodedToken.sub;
};

export const useUserStore = (...args) => useStore(userStore, ...args);
