export const listLabel = {
  marginBottom: 16,
};

export const listContainer = (hasAddButton) => [
  {
    display: 'flex',
    flexDirection: 'column',
  },
  hasAddButton && {
    marginBottom: 16,
  },
];

export const itemContainer = {
  display: 'flex',
  marginBottom: 16,
  borderRadius: 6,
  padding: '24px 16px',
  border: '1px solid var(--gray-700)',

  '&:last-child': {
    marginBottom: 0,
  },
};

export const itemContent = {
  zIndex: 100,
  minWidth: 45,
  minHeight: 50,
  backgroundColor: 'var(--theme-color)',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
};

export const deleteIconContainer = {
  cursor: 'pointer',
  paddingRight: 16,
  marginRight: 16,
  display: 'flex',
  alignItems: 'center',
  borderRight: '1px solid var(--gray-700)',
  backgroundColor: 'var(--theme-color)',
  transition: 'all .4s ease-in-out',
};
