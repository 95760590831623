import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Icon, Modal } from 'components/ui';
import { useTranslations } from '@veraio/strank';
import { forwardMessageModal } from './styles';
import { UsersSearch } from 'components/shared';
import { getUserId } from 'stores';

const ForwardMessageModal = (props) => {
  const { showModal, onSend, onClose } = props;
  const { getText } = useTranslations();
  const userId = getUserId();
  const [sendTo, setSendTo] = useState([]);
  const [resetUsersList, setResetUsersList] = useState(false);

  const handleClose = () => {
    onClose();
    setSendTo([]);
  };

  const handleSubmit = (formData, resetForm) => {
    if (!formData.users?.length) return;
    onSend(formData.users, (isError) => {
      resetForm(isError);
      !isError && setResetUsersList(true);
    });
  };

  const handleFilterUsers = useCallback((users) => users.filter((user) => user?.id !== userId));

  return (
    <Modal show={showModal} css={forwardMessageModal}>
      <Form className="forward-modal-container" values={{ users: sendTo }} onSubmit={handleSubmit}>
        <div className="modal-title">
          <h4>{getText('forwardTo')}...</h4>
          <Icon iconName="close" size={16} onClick={handleClose} />
        </div>

        <div className="modal-body">
          {({ values, setValues }) => (
            <UsersSearch
              multiple
              users={values.users}
              resetList={resetUsersList}
              filterUsers={handleFilterUsers}
              onChangeSelection={(selected) => setValues((prev) => ({ ...prev, users: selected }))}
            />
          )}
        </div>
        <div className="action-container">
          {({ onSubmit }) => (
            <Button small onClick={onSubmit}>
              {getText('forward')}
            </Button>
          )}
        </div>
      </Form>
    </Modal>
  );
};

ForwardMessageModal.propTypes = {
  showModal: PropTypes.bool,
  onSend: PropTypes.func,
  onClose: PropTypes.func,
};

export default ForwardMessageModal;
