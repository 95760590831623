import { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash-es';
import { hasAtLeastOneRole, hasRole, USER_ROLES } from '@oneecosystem/authenticate';
import { useTranslations } from '@veraio/strank';
import {
  addChatMessages,
  getUserId,
  resetUnreadMessages,
  setSelectedMessage,
  useChatStore,
  useMessagesStore,
} from 'stores';
import { historyDirection } from 'enums';
import { Button, Icon } from 'components/ui';
import { checkForNewDayTitle, checkForSenderData, getDateTimeLabel } from 'utils';
import { useChatMessages } from './useChatMessages';
import { chatBodyContainer } from './styles';
import ConversationRow from '../ConversationRow';

const ConversationContainer = (props) => {
  const containerRef = useRef(null);
  const { currentChat } = useChatStore();
  const { selectedMessage, messages: store } = useMessagesStore();
  const isAgentMod = hasRole(USER_ROLES.CHAT_MODERATOR);
  const isAdmin = hasAtLeastOneRole([USER_ROLES.CHAT_SUPER_ADMIN, USER_ROLES.CHAT_ADMIN]);
  const { getText } = useTranslations();
  const userId = getUserId();

  const [scrollTo, setScrollTo] = useState({ id: selectedMessage?.messageId });
  const [showRefresh, setShowRefresh] = useState();
  const { messages, state, setState, setPageIndex, setMessages, fetchMessages } = useChatMessages({
    ...props,
    chatMessages: currentChat ? store[currentChat.id]?.messages : [],
    unreadCount: currentChat ? store[currentChat.id]?.unreadCount : 0,
    currentChat,
    selectedMessage,
    canFetch: isAdmin || currentChat?.users?.find((user) => user.id === userId),
    onFetch: (data, chatData) =>
      addChatMessages(data, chatData, !currentChat?.users?.find((user) => user.id === userId)),
  });

  useEffect(() => {
    setState({ before: true, after: false, loadOnScroll: true, reset: false, new: false, loading: true });
    setMessages(store[currentChat?.id]?.messages?.slice(0, 20));
    setPageIndex(1);
    containerRef.current.scrollTop = 0;
    setShowRefresh(!currentChat?.users?.find((user) => user.id === userId));
  }, [currentChat?.id, currentChat?.users?.find((user) => user.id === userId)]);

  useEffect(() => {
    scrollTo && scrollToElementById(scrollTo.id, scrollTo.behavior);
    setState((prev) => ({ ...prev, loading: false }));
  }, [messages]);

  useEffect(() => {
    if (state.new && (containerRef?.current?.scrollTop > -250 || store[currentChat.id]?.unreadCount === 0))
      handleShowNewMessages();
  }, [state.new]);

  useEffect(() => {
    if (selectedMessage) {
      setState((prev) => ({ ...prev, loadOnScroll: false }));
      // Message is already rendered
      if (messages?.find((msg) => msg.messageId === selectedMessage.messageId)) {
        scrollToElementById(selectedMessage.messageId);
        setState((prev) => ({ ...prev, loadOnScroll: true, reset: false }));

        // Check if message is in the store
      } else if (store[currentChat.id]?.messages?.find((msg) => msg.messageId === selectedMessage.messageId)) {
        const stored = store[currentChat.id];
        const index = stored.messages?.findIndex((msg) => msg.messageId === selectedMessage.messageId);
        const slicedMessages = stored.messages?.slice(index - 19, index + 1);
        setScrollTo({ id: selectedMessage.messageId });
        setMessages(slicedMessages);
        setState((prev) => ({ ...prev, after: true, reset: false }));
      } else {
        setState((prev) => ({ ...prev, reset: true }));
        fetchMessages(selectedMessage.messageId, historyDirection.after, (payload) => {
          setScrollTo({ id: selectedMessage.messageId });
          setMessages([...payload, selectedMessage]);
        });
      }
    }
  }, [selectedMessage]);

  const loadMessages = (direction = historyDirection.before) => {
    if (!messages?.length) return;
    const lastMessage = direction === historyDirection.before ? messages[messages.length - 1] : messages[0];
    // Save current scroll position before loading new messages
    const previousScrollHeight = containerRef?.current?.scrollHeight;

    fetchMessages(lastMessage.messageId, direction, (payload) => {
      setScrollTo({ id: lastMessage.messageId, behavior: 'auto' });
      setMessages((prev) => (direction === historyDirection.before ? [...prev, ...payload] : [...payload, ...prev]));

      if (containerRef) {
        // Calculate the difference in scroll height after loading new messages
        const newScrollHeight = containerRef.current.scrollHeight;
        const scrollDiff = newScrollHeight - previousScrollHeight;

        // Adjust the scrollTop to maintain the user's position after loading messagess
        containerRef.current.scrollTop += scrollDiff;
      }
      if (direction === historyDirection.after) {
        const shouldAddToStore = !!store[currentChat.id]?.messages?.find((msg) =>
          payload.find((message) => message.messageId === msg.messageId),
        );
        shouldAddToStore &&
          addChatMessages(
            [...payload, ...messages],
            {
              targetType: currentChat.targetType,
              targetId: currentChat.targetId,
            },
            !currentChat?.users?.find((user) => user.id === userId),
          );
      }
    });
  };

  const scrollToElementById = (id, behavior) => {
    const el = document.getElementById(id);
    if (el) {
      el.scrollIntoView({ behavior: behavior ?? 'smooth' });
      setTimeout(() => {
        setSelectedMessage(null);
        setScrollTo(null);
      }, 1000);
    }
  };

  const handleUserScroll = debounce((e) => {
    const { scrollTop, scrollHeight } = e.target;

    if (state.loadOnScroll && !state.loading && scrollTop >= -50)
      currentChat?.id && resetUnreadMessages(currentChat.id);

    if (messages?.length && state.loadOnScroll && state.before && !state.loading && scrollHeight + scrollTop < 800)
      setPageIndex((prev) => prev + 1);
  }, 200);

  const handleRefreshMessages = () => {
    containerRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
    loadMessages(historyDirection.after);
    setState({ after: false, before: true, loadOnScroll: true, reset: false, loading: true, new: false });
  };

  const handleResetMessages = () => {
    containerRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
    setMessages(store[currentChat?.id]?.messages?.slice(0, 20));
    setState({ after: false, before: true, loadOnScroll: true, reset: false, loading: true });
  };

  const handleShowNewMessages = () => {
    if (messages && messages[0]?.messageId !== store[currentChat?.id]?.messages[0]?.messageId) {
      setMessages(store[currentChat?.id]?.messages?.slice(0, 20));
      setState({ after: false, before: true, reset: false, loadOnScroll: true });
    }

    containerRef?.current?.scrollTo({ top: 0, behavior: 'smooth' });
    currentChat?.id && resetUnreadMessages(currentChat.id);
    setState((prev) => ({ ...prev, new: false }));
  };

  return (
    <div ref={containerRef} css={chatBodyContainer} onScroll={handleUserScroll}>
      {messages?.length ? (
        <>
          {!!store[currentChat?.id]?.unreadCount && (
            <div className="new-messages">
              <div role="button" tabIndex={0} className="new-messages-button" onClick={handleShowNewMessages}>
                {getText('newMessages')}
                <Icon iconName="keyboard_arrow_down" />
              </div>
            </div>
          )}
          {state.reset && (
            <div className="reset-messages">
              <Icon iconName="arrow_downward" onClick={handleResetMessages} />
            </div>
          )}
          {showRefresh && (
            <div className="reset-messages">
              <Icon iconName="arrow_downward" onClick={handleRefreshMessages} />
            </div>
          )}
          {!state.loadOnScroll && state.after && (
            <div className="load-more-container">
              <Button small type="default" onClick={() => loadMessages(historyDirection.after)}>
                {getText('loadMore')}
              </Button>
            </div>
          )}
          {messages.map((msg, index) => (
            <div key={`message-${msg.messageId}-${index}`}>
              {checkForNewDayTitle(
                msg.messageId,
                messages[index !== messages.length - 1 ? index + 1 : index]?.messageId,
              ) && (
                <div className="chat-day-title">
                  <span className="title">{getDateTimeLabel(msg.messageId, true)}</span>
                </div>
              )}
              <ConversationRow
                message={msg}
                isSelected={selectedMessage?.messageId === msg.messageId}
                isAuthor={msg.author?.id === userId}
                isAdmin={isAdmin}
                showSender={checkForSenderData(msg, messages[index > 0 ? index - 1 : 0])}
              />
            </div>
          ))}
          {state.before ? (
            !state.loadOnScroll && (
              <div className="load-more-container">
                <Button small type="default" onClick={() => loadMessages(historyDirection.before)}>
                  {getText('loadMore')}
                </Button>
              </div>
            )
          ) : (
            <span className="no-messages">{getText('startOfConversation')}</span>
          )}
        </>
      ) : (
        messages && (
          <div className="empty-chat-container">
            <p className="no-messages-text">{getText('noMessages')}</p>
            {currentChat?.users?.find((user) => user.id === userId) &&
              ((currentChat?.readOnly && (isAgentMod || isAdmin)) || !currentChat?.readOnly) && (
                <p className="start-chat-text">{getText('beTheFirstToSayHi', { emoji: '👋' })}</p>
              )}
          </div>
        )
      )}
    </div>
  );
};

ConversationContainer.propTypes = {
  containerRef: PropTypes.object,
};

export default ConversationContainer;
