import { commonStyles } from 'styles';

export const inputDateContainer = {
  position: 'relative',

  '& .icon-close': {
    position: 'absolute',
    top: 6,
    right: 4,
  },
};

export const datePickerContainer = (hasValidation, hasError, props) => {
  return [
    {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      width: '100%',

      ' .form-control': {
        color: 'var(--secondary)',
        border: `1px solid ${hasError ? 'var(--error)' : 'var(--border-color)'}`,
        backgroundColor: hasError ? 'var(--error-light)' : 'var(--theme-color)',
        borderRadius: '0.3rem',
        minHeight: props.small ? '3.6rem' : '4.8rem',
        padding: props.small ? '0.35em 0.85em' : '0.7em 0.85em',
        width: '100%',
        transition: 'box-shadow .2s ease-in-out, border-color .2s ease-in-out',
        marginBottom: hasValidation ? 6 : 0,
        outline: 'blue',
      },

      '& .clearIcon': {
        position: 'absolute',
        right: -30,
        bottom: 0,
        border: '1px solid var(--border-color)',
        borderTopRightRadius: 17,
        borderBottomRightRadius: 17,
        borderLeft: 'none',
        display: 'flex',
        padding: '5px 5px 5px 20px',
      },

      '.picker-container': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'var(--gray-700)',
        borderRight: '1px solid var(--border-color-weak)',

        '.icon': {
          color: 'var(--gray-500)',
          marginRight: 8,
        },
      },

      '.label-styles': { paddingLeft: 8, fontWeight: 600, fontSize: 12 },
    },
    props.label && {
      marginBottom: 7,
    },
    props.showClear && {
      marginRight: 32,
    },
    hasError && {
      '& > .form-control::placeholder': {
        color: 'var(--secondary-weaker)',
      },
    },
    props.disabled
      ? {
          ' > .form-control': {
            borderColor: 'var(--border-color-weak)',
            color: 'var(--gray-700)',
            cursor: 'not-allowed',

            '&::placeholder': {
              color: 'var(--gray)',
            },
          },
        }
      : {
          '& > .form-control:focus': {
            borderColor: 'var(--highlight)',
            outline: 'none',

            '&::placeholder': {
              color: 'var(--secondary-weaker)',
            },
          },
        },
    ...commonStyles(props),
  ];
};
