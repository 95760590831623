import { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../Icon';
import { collapseContainer, collapseHeader } from './styles';

const Collapse = (props) => {
  const { header, cardIconName, children, className, disabled } = props;
  const childsRef = useRef();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div css={collapseContainer(props)} {...(className && { className })}>
      <div
        role="button"
        tabIndex={0}
        onClick={() => !disabled ? setIsOpen((prev) => !prev) : {}}
        className="collapse-header"
        css={collapseHeader(isOpen, disabled)}>
        <div className="title-icon">
          {cardIconName && <Icon iconName={cardIconName} size={20} color="gray-100" className="icon" />}
          <span className="collapse-title">{header}</span>
        </div>
        {!disabled && <Icon size={16} iconName="expand_more" color="gray-100" className="arrow-icon" />}
      </div>
      {isOpen && !disabled && (
        <div ref={childsRef} className="collapse-content">
          {children}
        </div>
      )}
    </div>
  );
};

Collapse.propTypes = {
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  cardIconName: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Collapse;
