import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Button, Form, Icon, Input, inputValidation, Modal, UsersSearch } from 'components';
import { createGroupContainer } from './styles';
import { getUserId } from 'stores';

const CreateGroupModal = (props) => {
  const { showModal, onClose, onCreate } = props;
  const { getText } = useTranslations();
  const userId = getUserId();
  const [group, setGroup] = useState({});
  const [resetUsersList, setResetUsersList] = useState(false);

  const handleCreateGroup = (val, resetForm) => {
    onCreate(val, (isError) => {
      resetForm(isError);
      !isError && setResetUsersList(true);
    });
    setGroup({});
  };

  const handleClose = () => {
    onClose();
    setGroup({});
  };
  const handleFilterUsers = useCallback((users) => users.filter((user) => user?.id !== userId));

  return (
    <Modal show={showModal} onClose={onClose} css={createGroupContainer}>
      <Form className="group-modal-container" values={group} onSubmit={handleCreateGroup}>
        <div className="modal-title">
          <h4>{getText('createGroup')}</h4>
          <Icon iconName="close" size={16} onClick={handleClose} />
        </div>

        <div className="modal-body">
          <Input
            required
            formId="name"
            className="modal-input"
            placeholder={getText('enterName')}
            label={getText('groupName')}
            validate={inputValidation('required', 'chatName')}
          />

          {({ values, setValues }) => (
            <div className="members-container">
              <UsersSearch
                label={getText('groupMembers')}
                multiple
                users={values.users}
                resetList={resetUsersList}
                filterUsers={handleFilterUsers}
                onChangeSelection={(selected) => setValues((prev) => ({ ...prev, users: selected }))}
              />
            </div>
          )}
        </div>

        <div className="action-container">
          {({ onSubmit }) => (
            <>
              <Button small type="secondary" className="close-button" onClick={handleClose}>
                {getText('close')}
              </Button>
              <Button small onClick={onSubmit}>
                {getText('createGroup')}
              </Button>
            </>
          )}
        </div>
      </Form>
    </Modal>
  );
};

CreateGroupModal.propTypes = {
  showModal: PropTypes.bool,
  onCreate: PropTypes.func,
  onClose: PropTypes.func,
};

export default CreateGroupModal;
