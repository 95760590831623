import { useTranslations } from '@veraio/strank';
import { permissionsModalContainer } from './styles';
import ConfirmationModal from '../ConfirmationModal';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Spinner } from 'components/ui';

const PermissionsModal = (props) => {
  const { showModal, onOk } = props;
  const [loading, setLoading] = useState(false);
  const { getText } = useTranslations();

  const handleOkClick = async () => {
    if (loading) return;

    setLoading(true);
    await onOk();
    setLoading(false);
  };

  return (
    <ConfirmationModal
      title={getText('notificationsPermission')}
      showModal={showModal}
      okButtonText={getText('LetsDoIt')} // Should be changed
      onOk={handleOkClick}>
      <div css={permissionsModalContainer}>
        <p className="main-text">{getText('turnOnNotifications')}</p>
        <hr />
        <ul>
          <li>{getText('messagesWhenOffline')}</li>
          <li>{getText('changePermission')}</li>
        </ul>
      </div>
      <Spinner loading={loading} size={40} />
    </ConfirmationModal>
  );
};

ConfirmationModal.propTypes = {
  showModal: PropTypes.bool,
  onOk: PropTypes.func,
};

export default PermissionsModal;
