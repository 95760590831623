import { keyframes } from '@emotion/react';
export const publicContentContainer = {
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'row',

  '.action-column': {
    padding: '0 128px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',

    '.logo': {
      height: 40,
      width: 'auto',
      position: 'absolute',
      top: 20,
      left: 20,
      cursor: 'pointer',
    },

    '.form-container': {
      maxWidth: 400,

      '.title': {
        fontWeight: 500,
        marginBottom: 24,
      },

      '.forgot-button': {
        padding: 0,
        color: 'var(--primary)',
        marginBottom: 16,
      },

      '.action-container': {
        display: 'flex',
        marginBottom: 24,

        '.login-button': {
          width: '100%',
          borderRadius: 8,
          padding: 16,
          fontWeight: 600,
          fontSize: 16,
        },
      },
    },

    '.paragraph': {
      maxWidth: 400,
      color: 'var(--secondary)',
      lineHeight: 1.4,
      marginBottom: 12,
      fontSize: 14,

      '.underline-text': {
        textDecoration: 'underline',
      },
    },
  },

  '.pictureContainer': {
    backgroundColor: 'var(--primary)',
    position: 'relative',
  },
};

export const forgotPasswordContainer = {
  width: '100%',
  height: '100%',
  padding: '0 128px',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  '.logo': {
    height: 40,
    width: 'auto',
    position: 'absolute',
    top: 20,
    left: 20,
    cursor: 'pointer',
  },

  '.form-container': {
    maxWidth: 500,
    border: '1px solid var(--gray-100)',
    borderRadius: 10,
    boxShadow: 'var(--box-shadow-dark)',
    padding: 32,

    '.lock-icon-container': {
      backgroundColor: 'var(--primary-weaker)',
      padding: 8,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 40,
      borderRadius: 10,
      marginBottom: 12,
    },

    '.title': {
      fontWeight: 500,
      marginBottom: 24,
    },

    '.action-container': {
      display: 'flex',
      marginBottom: 24,

      '.login-button': {
        width: '100%',
        borderRadius: 8,
        padding: 16,
        fontWeight: 600,
        fontSize: 16,
      },
    },
  },

  '.info-container': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',

    '.paragraph': {
      maxWidth: 500,
      fontSize: 16,
    },

    '.button': {
      padding: 0,
      color: 'var(--info)',
      fontSize: 16,
    },
  },
};

const lock = keyframes`
  50% {top:-4%;}
	100% {top:-6%;}
`;

const colors = keyframes`
  50% {transform:scale(1.1);}
  100% {color:var(--primary-dark)}
`;

export const mainContainer = {
  width: '100%',
  height: 'calc(100vh - 40px)',
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  background: 'linear-gradient(180deg,  var(--info-light) 0%, var(--info) 100%)',

  'div[role=button]': {
    color: 'rgb(162,163,166)',
  },

  h1: {
    textTransform: 'uppercase',
    textAlign: 'center',
    fontSize: '30vw',
    display: 'block',
    margin: '0',
    color: '#9ae1e2',
    position: 'relative',
    zIndex: '0',
    animation: `${colors} .4s ease-in-out forwards`,
    animationDelay: '1.7s',

    '&:before': {
      content: '"U"',
      position: 'absolute',
      top: '-9%',
      right: '40%',
      transform: 'rotate(180deg)',
      fontSize: '15vw',
      color: 'var(--primary)',
      zIndex: '-1',
      textAlign: 'center',
      animation: `${lock} .2s ease-in-out forwards`,
      animationDelay: '1.5s',
    },
  },

  h2: {
    color: '#9ae1e2',
    fontSize: '5vw',
    margin: '0',
    textTransform: 'uppercase',
    textAlign: 'center',
    animation: `${colors} .4s ease-in-out forwards`,
    animationDelay: '2s',
  },
};
