import { useState, useEffect, useImperativeHandle } from 'react';
import { isFunction } from 'lodash-es';
import inputValidation from '../inputValidation';

export const useInput = ({
  value: initValue = '',
  onChange,
  onError,
  onBlur,
  validate,
  pattern,
  disabled = false,
  required = false,
  isTouched: isTouchedInit = false,
  componentRef,
  number = false,
}) => {
  const strVal = initValue?.toString ? initValue.toString() : initValue ?? '';
  const [value, setValue] = useState(strVal);
  const [error, setError] = useState();
  const [isTouched, setIsTouched] = useState(isTouchedInit ?? false);
  const getValue = val => (number && val ? Number(val) : val);

  useEffect(() => {
    setValue(strVal);
  }, [strVal]);

  useEffect(() => {
    setIsTouched(isTouchedInit);
  }, [isTouchedInit]);

  useEffect(() => {
    isTouched && checkForError(value);
  }, [isTouched]);

  useImperativeHandle(componentRef, () => ({
    value,
    changeValue: newValue => handleChange({ target: { value: newValue } }),
    checkForError,
    changeError: setError,
    changeIsTouched: setIsTouched,
  }));

  const handleChange = event => {
    const { target } = event;
    const { value: newValue } = target;

    if (newValue.length && isFunction(pattern) && !pattern(newValue.slice(-1))) return;

    setValue(newValue);
    checkForError(newValue);
    isFunction(onChange) && onChange(getValue(newValue));
  };

  // On blur is used only to change the flag for touched
  const handleBlur = (e) => {
    !isTouched && setIsTouched(true);
    checkForError(value);
    isFunction(onBlur) && onBlur(e);
  };

  const checkForError = newValue => {
    let newError = null;

    // If validate function is provided check for error
    if (isFunction(validate)) newError = validate(getValue(newValue));
    else if (required) newError = inputValidation('required')(getValue(newValue));

    setError(newError);
    isFunction(onError) && onError(newError);
  };

  return {
    // Input props
    value,
    onChange: handleChange,
    onBlur: handleBlur,
    disabled,
    // Form related props
    error,
    isTouched,
  };
};
