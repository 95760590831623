import { commonStyles } from 'styles';

export const searchBarContainer = (props) => [
  {
    position: 'relative',
    zIndex: 50,
    width: '100%',
    borderRadius: 8,
  },
  ...commonStyles(props),
];

export const searchBarInputContainer = (props) => [
  {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 4,
    minHeight: '4.8rem',
    width: '100%',
    color: 'var(--secondary)',
    border: '1px solid var(--border-color)',
    backgroundColor: 'var(--gray-500)',
  },
  ...commonStyles(props),
];

export const searchBarInput = {
  fontSize: '1.3rem',
  lineHeight: 1.2,
  paddingRight: '1rem',
  outline: 'none',
  padding: '0.8rem 1rem',
  border: 'none',
  background: 'transparent',
  borderRadius: 4,
  width: '100%',
  color: 'var(--secondary)',

  '&:focus, &:active': {
    border: 'none',
    boxShadow: 'none',
  },
};

export const searchBarInputIcon = {
  cursor: 'pointer',
  padding: '0.8rem 1rem 0.8rem 1rem',
  color: 'var(--secondary-weaker)',
};

export const searchBarSuggestionsContainer = {
  position: 'absolute',
  top: '100%',
  left: 0,
  right: 0,
  color: 'var(--secondary-weaker)',
  maxHeight: 185,
  zIndex: 20,
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  backgroundColor: 'var(--gray-500)',
  boxShadow: 'var(--box-shadow)',
  border: '1px solid var(--active-background)',
};

export const searchBarSuggestionItem = {
  padding: '0.8rem 1.5rem',
  color: 'var(--secondary-weaker)',
  display: 'flex',
  alignItems: 'center',
  gap: 5,

  '&:focus, &:hover': {
    outline: 'none',
    color: 'var(--secondary)',
  },
};
