export const avatarContainer = (statusColor, size, border) => [
  {
    position: 'relative',
    '.avatar-image': {
      width: size ? size : 36,
      height: size ? size : 36,
      borderRadius: '50%',
    },
  },
  border && {
    backgroundColor: 'var(--background-stronger)',
    borderRadius: '50%',
    '.avatar-image': {
      border: '1px solid var(--border-color-weak)',
      padding: '0.25rem',
    },
  },
  statusColor && {
    '.status-container': {
      display: statusColor ? 'flex' : 'none',
      flexDirection: 'row',
      alignItems: 'center',
      position: 'absolute',
      right: size ? -6 : -7,
      top: size ? 70 : 27,

      '.status-indicator': {
        width: size ? 15 : 10,
        height: size ? 15 : 10,
        backgroundColor: `var(--${statusColor}, ${statusColor})`,
        border: '2px solid var(--theme-color)',
        borderRadius: '50%',
        marginRight: 8,
      },
    },
  },
];
