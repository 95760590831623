export const sendMessageContainer = (hasFiles, isEditMode) => [
  {
    minHeight: isEditMode ? 'auto' : hasFiles ? 170 : 90,
    borderTop: '1px solid var(--border-color-weak)',

    '.edit-mode-title': {
      fontSize: 12,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: '0.75rem',
      color: 'var(--secondary)',
      '>*': {
        margin: 'auto 1.5rem',
      },
    },

    '.message-container': {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      margin: isEditMode ? '0.5rem 1rem 1rem 1.5rem' : '1.5rem 1rem 1rem 1.5rem',

      '.action-icon': {
        color: 'var(--primary-weak)',
        margin: 'auto 15px',
        cursor: 'pointer',
        ':hover': {
          color: 'var(--primary-stronger)',
        },
      },

      '.emoji-list': {
        zIndex: 9,
        overflowY: 'auto',
        position: 'absolute',
        bottom: 80,
        right: 10,
      },

      '.message-input': {
        marginBottom: 0,
        resize: 'none',
        '.input': {
          height: 40,
        },
      },
      '.file-input': {
        '.input': {
          display: 'none',
        },
      },

      '.send-button': {
        margin: 10,
        '.send-icon': {},
      },
    },

    '.files-container': {
      padding: '1rem',
      borderTop: '1px solid var(--border-color)',
      overflowX: 'none',
      display: 'flex',
      gap: 5,

      '.uploaded-file': {
        border: '2px solid var(--border-color)',
        borderRadius: '4px',
        padding: '0.5rem',
        position: 'relative',
        display: 'flex',
        '&.image-file': {
          border: '2px solid transparent',
          padding: 0,

          img: {
            borderRadius: '4px',
          },
        },

        '.remove-icon': {
          position: 'absolute',
          top: -5,
          right: -5,
          backgroundColor: 'var(--backgorund)',
          borderRadius: '50%',
          padding: 2,
          border: '1px solid var(--border-color-weak)',
          color: 'var(--secondary)',
          fontWeight: 700,
        },
      },
    },
  },
];
