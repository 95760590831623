import PropTypes from 'prop-types';
import { Input } from 'components';
import { searchInputContainer } from './styles';

const SearchInput = (props) => {
  const { value, placeholder, onChange } = props;

  return (
    <div css={searchInputContainer}>
      <Input
        small
        className="search-input"
        value={value}
        placeholder={`${placeholder}...`}
        leftIcon={{
          className: 'search-input-left-icon',
          iconName: 'search',
          color: 'secondary-weaker',
          size: 22,
        }}
        rightIcon={{
          className: 'search-input-left-icon',
          iconName: 'close',
          color: 'secondary-weaker',
          size: 20,
          onClick: () => onChange()
        }}
        onChange={onChange}
      />
    </div>
  );
};

SearchInput.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};

export default SearchInput;
