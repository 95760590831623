import { useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { getUserDisplayName } from 'utils';
import { Avatar } from 'components/shared';
import { messageContainer } from './styles';
import MessageBox from '../MessageBox';

const ConversationRow = (props) => {
  const { message, showSender, isSelected, isAuthor, isAdmin } = props;
  const { getText } = useTranslations();
  const [showDeletedMessages, setShowDeletedMessages] = useState([]);

  return (
    <div css={messageContainer(isAuthor, isSelected)} id={message.messageId}>
      {!message.deletedAt || showDeletedMessages.includes(message.messageId) ? (
        <>
          <div className="avatar-column">{showSender && <Avatar picThumbnailUrl={message.author?.pictureUrl} />}</div>
          <div className="message-column">
            <MessageBox
              message={message}
              isOwn={isAuthor}
              onHide={() => setShowDeletedMessages((prev) => prev.filter((id) => id !== message.messageId))}
            />
            <div className="message-box-item" />
            {showSender && <p className="sender-name">{getUserDisplayName(message.author)}</p>}
          </div>
        </>
      ) : (
        isAdmin && (
          <button
            className="deleted-message"
            onClick={() => setShowDeletedMessages((prev) => [...prev, message.messageId])}>
            {getText('messageWasDeleted')}
          </button>
        )
      )}
    </div>
  );
};

export default ConversationRow;
