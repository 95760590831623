import { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import axios from 'axios';
import { setStrankConfig } from '@veraio/strank';
import { setAuthConfig, AUTH_EVENTS_TYPES, renewSession } from '@oneecosystem/authenticate';
import { Global } from '@emotion/react';
import { LoadIcons, ThemeProvider } from 'components';
import { ROUTES } from 'enums';
import { initializeUser } from 'stores';
import { GlobalReset } from 'styles';
import BaseApp from './screens/App';
// import { registerServiceWorker } from 'messaging';

// Here you can place all of your wrappers inside for your app
setAuthConfig({
  //REMOVE TAG CHANGE CLIENT_ID
  identity: { domain: authorizations.keycloakUrl, clientId: 'OneChat' },
  oneLifeDomain: apiUrls.lifeApiUrl,
  [AUTH_EVENTS_TYPES.SET_TOKEN]: (token) => {
    axios.defaults.headers.common.Authorization = `Bearer ${token.access_token}`;
  },
  [AUTH_EVENTS_TYPES.LOGIN_WITH_PASSWORD]: initializeUser,
  [AUTH_EVENTS_TYPES.RENEW_SESSION]: initializeUser,
  [AUTH_EVENTS_TYPES.LOGOUT]: () => {
    window.location = ROUTES.Login;
  },
});

setStrankConfig({ parseHtml: true, environment: strankEnvironment, storage: window.localStorage });

const App = () => {
  useEffect(() => {
   renewSession();
  }, []);

  return (
    <Router>
      <ThemeProvider>
        <Global styles={GlobalReset} />
        <BaseApp />
        <LoadIcons />
      </ThemeProvider>
    </Router>
  );
};

const root = createRoot(document.getElementById('one-chat-portal'));
root.render(<App />);
