export const getCacheVal = (key) => {
  const strValue = localStorage.getItem(key);
  let value = null;
  try {
    value = JSON.parse(strValue);
  } catch (e) {
    value = strValue;
  }

  return value;
};

export const setCacheVal = (key, val) => localStorage.setItem(key, JSON.stringify(val));

export const removeCacheVal = (key) => localStorage.removeItem(key);
