/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
import { flattenDeep, isFunction } from 'lodash-es';

const validators = {
  required: {
    regExp: /\S/,
    msg: 'Required field',
  },
  email: {
    regExp:
      /^$|^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    msg: 'Invalid Email',
  },
  username: {
    regExp: /^$|^[A-Za-z0-9_+\-.!#$'^`~@]{1,25}$/,
    msg: 'Invalid username',
  },
  chatName: {
    regExp: /^$|^.{1,50}$/,
    msg: 'The Name must be maximum 50 characters long.',
  },
  description: {
    regExp: /^$|^.{0,250}$/,
    msg: 'The Description must be maximum 250 characters long.',
  },
  password: {
    regExp: /(?=.*[!?/'`“”~|@#$%^&*()\\\-_=+{};:,<.>[\]])/,
    msg: 'Passwords must have at least one non alphanumeric character',
  },
  passwordLength: {
    regExp: /^$|^.{8,}$/,
    msg: 'The Password must be at least 8 characters long.',
  },
  phone: {
    regExp: /^$|^[0-9]{1,6}\s[0-9]{6,}$/,
    msg: 'Invalid phone number',
  },
  postCode: {
    regExp: /^$|^[0-9]{6}$/,
    msg: 'Invalid post code',
  },
  price: {
    regExp: /^$|^(?:[1-9][0-9]{0,})\.[0-9]{1,2}|[1-9][0-9]*$/,
    msg: 'Invalid number',
  },
  positiveInt: {
    regExp: /^$|^[1-9][0-9]*$/,
    msg: 'Invalid number',
  },
  validHex: {
    regExp: /^$|^#([0-9a-f]{3}|[0-9a-f]{6}|[0-9a-f]{8})$/i,
    msg: 'Invalid HEX color',
  },
  googleMaps: {
    regExp: /^$|^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/,
    msg: 'Invalid latitude/longitude',
  },
  lowercase: {
    regExp: /[a-z]+/,
    msg: 'Must have at least one lowercase ("a"-"z")',
  },
  uppercase: {
    regExp: /[A-Z]+/,
    msg: 'Must have at least one uppercase ("A"-"Z")',
  },
  lettersNumbersPunctuation: {
    regExp: /^[a-zA-Z0-9-.,\s]*$/,
    msg: 'Must have at letters, numbers and punctuations',
  },
  lettersNumbersDash: {
    regExp: /^[a-zA-Z0-9\s-]*$/,
    msg: 'Must have at letters, numbers and dash',
  },
  onlyNumbers: { regExp: /^[0-9]*$/, msg: 'Must have only numbers' },
  creditCard: { regExp: /^[4|5]/, msg: 'Supports only Visa and MasterCard' },
  cvvcode: { regExp: /^[0-9]{0,3}$/, msg: 'Invalid cvv code' },
  electronCard: { regExp: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/, msg: 'Invalid electron card number' },
  maestroCard: {
    regExp: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
    msg: 'Invalid maestro card number',
  },
  dankortCard: { regExp: /^(5019)\d+$/, msg: 'Invalid dankort card number' },
  interpaymentCard: { regExp: /^(636)\d+$/, msg: 'Invalid interpayment card number' },
  unionpayCard: { regExp: /^(62|88)\d+$/, msg: 'Invalid unionpay card number' },
  visaCard: { regExp: /^4[0-9]{12}(?:[0-9]{3})?$/, msg: 'Invalid visa card number' },
  mastercardCard: {
    regExp: /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/,
    msg: 'Invalid mastercard card number',
  },
  americanExpressCard: { regExp: /^3[47][0-9]{13}$/, msg: 'Invalid American express card number' },
  dinersCard: { regExp: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/, msg: 'Invalid diners card number' },
  discoverCard: { regExp: /^6(?:011|5[0-9]{2})[0-9]{12}$/, msg: 'Invalid discover card number' },
  jcbCard: { regExp: /^(?:2131|1800|35\d{3})\d{11}$/, msg: 'Invalid jcb card number' },
};

export default (...inputValidators) =>
  (value) => {
    const setOfValidators = flattenDeep(inputValidators);

    for (const validator of setOfValidators) {
      const validatorName = validator?.name ?? validator;
      const render = validator?.render;
      const { regExp, msg } = validators[validatorName];

      // Bypass null/undefined because the regExp api is stupid and if you pass it null/undefined it will convert them to string
      if (!regExp.test(`${value ?? ''}`)) {
        return {
          msg,
          ...(isFunction(render) && { render }),
        };
      }
    }

    return null;
  };
