import { useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { flatten } from 'lodash-es';
import {
  getText,
  useTranslations,
  getSavedLanguage,
  getBrowserLanguage,
  initTranslationsStore,
  initLanguagesStore,
} from '@veraio/strank';
import { manageServiceWorker } from 'messaging';
import {
  Image,
  MenuItem,
  SideNav,
  NavigationProvider,
  Notifications,
  useDeepEffect,
  useTheme,
  useUrlParams,
  PermissionsModal,
} from 'components';
import { chatTypes, ROUTES } from 'enums';
import {
  useUserStore,
  initCountries,
  useMessagesStore,
  initChatsFromCache,
  initMessagesFromCache,
  cacheChatsData,
  cacheMessagesData,
  getUserId,
} from 'stores';
import { disconnectWebSocket, connectWebSocket } from 'services';
import logo from 'assets/images/logo.svg';
import { PrivateRoutes } from './private/PrivateRoutes';
import { PublicRoutes } from './public/PublicRoutes';
import { Logout, AuthorizationCallback } from './authorization';
import { NotFound } from './public';
import { Channels, Chats, Languages, MyProfile, Settings, SiteMode, User } from './private';
import Chat from './private/Chat';
import { mainContent, pageContainer, sideNavContent } from './styles';
import 'styles/themes.css';
import { isNil } from '@veraio/core';

const App = () => {
  const { isAuthenticated } = useUserStore();
  const { theme } = useTheme();
  const { messages } = useMessagesStore();
  const { changeLanguage, allEnvLanguages } = useTranslations();
  const history = useHistory();
  const [hasUnreadMessagesBadge, setHasUnreadMessagesBadge] = useState({ chats: false, channels: false });
  const [fcmToken, setFcmToken] = useState(null);
  const [isFirstOpen, setIsFirstOpen] = useState(true);
  const { queryParams, clearQueryParams } = useUrlParams();
  const [openPermissionModal, setOpenPermissionModal] = useState(false);

  useDeepEffect(() => {
    allEnvLanguages?.length && changeLanguage(getSavedLanguage() ?? getBrowserLanguage());
  }, [allEnvLanguages]);

  useEffect(() => {
    initTranslationsStore();
    initCountries();
    initLanguagesStore();

    document.addEventListener('visibilitychange', cacheDataInLocalStorage);

    return () => {
      document.removeEventListener('visibilitychange', cacheDataInLocalStorage);
    };
  }, []);

  useEffect(() => {
    document.body.setAttribute('data-theme', theme);
  }, [theme]);

  useEffect(() => {
    if (isAuthenticated) {
      isFirstOpen && getDataFromLocalStorage();
      checkNotificationsPermission();
    }

    // Do we need this?
    return () => {
      disconnectWebSocket();
    };
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isNil(fcmToken)) connectWebSocket(fcmToken.length ? fcmToken : getUserId());
  }, [fcmToken]);

  useEffect(() => {
    const allMessages = Object.values(messages) ?? [];
    const hasUnreadChats = allMessages.find(
      (chatMessages) => chatMessages.unreadCount > 0 && chatMessages.messages[0].targetType !== chatTypes.channel,
    );
    const hasUnreadChannels = allMessages.find(
      (chatMessages) => chatMessages.unreadCount > 0 && chatMessages.messages[0]?.targetType === chatTypes.channel,
    );
    setHasUnreadMessagesBadge({
      chats: !!hasUnreadChats,
      channels: !!hasUnreadChannels,
    });
  }, [messages]);

  const checkNotificationsPermission = () => {
    if (Notification.permission === 'granted') manageServiceWorker(setFcmToken);
    else if (Notification.permission === 'denied') setFcmToken('');
    else setOpenPermissionModal(true);
  };

  const handleGetNotificationsPermission = async () => {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') await manageServiceWorker(setFcmToken);
    else setFcmToken('');

    setOpenPermissionModal(false);
    return permission;
  };

  const getDataFromLocalStorage = () => {
    initMessagesFromCache();
    const params = queryParams.id ? { id: queryParams.id, targetType: queryParams.targetType } : null;
    initChatsFromCache(params);

    setIsFirstOpen(false);
    clearQueryParams(['id', 'targetType']);
  };

  const cacheDataInLocalStorage = () => {
    if (document.visibilityState === 'hidden' && isAuthenticated) {
      cacheChatsData();
      cacheMessagesData();
    }
  };

  return (
    <NavigationProvider>
      <Switch>
        <Route exact path={ROUTES.Logout} component={Logout} />
        <Route exact path={ROUTES.AuthorizationCallback} component={AuthorizationCallback} />
      </Switch>
      <Notifications />
      <PermissionsModal showModal={openPermissionModal} onOk={handleGetNotificationsPermission} />
      {isAuthenticated ? (
        <div css={pageContainer}>
          <SideNav
            homeRoute={'/chats'}
            routes={flatten(Object.values(PrivateRoutes).map((el) => el.internal))}
            notFoundComponent={NotFound}
            styles={sideNavContent}>
            <MenuItem
              externalComponent={
                <Image src={logo} onClick={() => history.push('/')} width={30} height={30} className="logo-navbar" />
              }
            />
            <div className="middle-container">
              <MenuItem url={ROUTES.MyProfile} icon="person" label={getText('profile')} component={MyProfile} />
              <MenuItem
                url={ROUTES.Chats}
                icon="chat"
                withBadge={hasUnreadMessagesBadge.chats}
                label={getText('chats')}
                component={Chats}
              />
              <MenuItem
                url={ROUTES.Channels}
                icon="groups"
                withBadge={hasUnreadMessagesBadge.channels}
                label={getText('channels')}
                component={Channels}
              />
              <MenuItem url={ROUTES.Settings} icon="settings" label={getText('settings')} component={Settings} hideOnSmallScreen/>
            </div>
            <div className="bottom-container">
              <MenuItem externalComponent={<Languages />} />
              <MenuItem externalComponent={<SiteMode hideOnSmallScreen />} />
              <MenuItem externalComponent={<User />} />
            </div>
          </SideNav>
          <Chat styles={mainContent} />
        </div>
      ) : (
        <PublicRoutes />
      )}
    </NavigationProvider>
  );
};

export default App;
