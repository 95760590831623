import { useEffect, useState } from 'react';
import { uuidv7 } from 'uuidv7';
import { getMessages } from 'services';
import { historyDirection } from 'enums';
import { isNil } from '@veraio/core';

const PAGE_SIZE = 20;

export const useChatMessages = ({ currentChat, chatMessages, unreadCount, selectedMessage, canFetch, onFetch }) => {
  const [messages, setMessages] = useState();
  const [state, setState] = useState({
    before: true,
    after: false,
    loadOnScroll: true,
    reset: false,
    new: false,
    loading: true,
  });
  const [pageIndex, setPageIndex] = useState(1);

  useEffect(() => {
    currentChat && !state.new && !selectedMessage && !state.reset && updateMessages();
  }, [chatMessages, currentChat?.id]);

  useEffect(() => {
    if (chatMessages && messages && pageIndex) {
      // If there are more than 20 messages to get from the store, get them
      if (chatMessages.length - messages.length >= PAGE_SIZE) return getMessagesFromStore({ pageIndex });

      // If we don't have enough messages to get from the store, fetch them from BE
      const lastMessage = messages.slice(-1)[0];
      lastMessage && fetchMessages(lastMessage.messageId);
    }
  }, [pageIndex]);

  const updateMessages = () => {
    setState((prev) => ({ ...prev, loading: true }));
    if (!chatMessages?.length) {
      pageIndex !== 1 && setPageIndex(1);
      !isNil(chatMessages) && setMessages([]);
      fetchMessages(uuidv7());
    } else if (!messages?.length) {
      pageIndex !== 1 && setPageIndex(1);
      getMessagesFromStore({ pageIndex: 1 });

      // If messages in store are less than PAGE_SIZE, fetch from BE more messages.
      chatMessages?.length < PAGE_SIZE && fetchMessages(chatMessages.slice(-1)[0]?.messageId);
    } else if (messages[0]?.messageId === chatMessages[0]?.messageId) {
      // Old messages were added or some messages were edited/deleted
      // (!!!) If messages were edited or deleted - should refresh all messages

      // If there are less than 20 messages to get from the store, should fetch from BE
      const shouldFetch = chatMessages?.length - messages.length < PAGE_SIZE;
      getMessagesFromStore({ pageIndex });

      // If we don't have enough messages to get from the store, fetch them from BE
      shouldFetch && state.before && fetchMessages(messages.slice(-1)[0].messageId);
    } else {
      // New messages have been added to the store
      if (state.loadOnScroll) {
        const lastIndex = chatMessages?.findIndex((msg) => msg.messageId === messages.slice(-1)[0]?.messageId);
        const lastToGet = lastIndex >= 0 ? lastIndex + 1 : PAGE_SIZE * pageIndex + unreadCount;
        const slicedData = chatMessages?.slice(0, lastToGet) ?? [];
        setMessages(slicedData);
        setPageIndex(slicedData ? Math.ceil(slicedData.length / PAGE_SIZE) || 1 : 1);
      }

      setState((prev) => ({ ...prev, new: true }));
    }
  };

  const getMessagesFromStore = (options) => {
    if (!currentChat || !chatMessages) {
      setMessages([]);
      setState((prev) => ({ ...prev, before: false }));
      return;
    }

    // Get next messages from store
    const startIndex = messages?.length ? chatMessages?.findIndex((msg) => msg.messageId === messages[0].messageId) : 0;
    const messagesSlice = chatMessages?.slice(startIndex, startIndex + PAGE_SIZE * options.pageIndex);
    setMessages(messagesSlice ?? []);
  };

  const fetchMessages = (lastMessageId, direction = historyDirection.before, callback) => {
    //If we don't have message id we can generate UUID which timestamp will be now and we will get messages before now
    if (!lastMessageId || !currentChat || !canFetch) return;

    const data = {
      targetType: currentChat.targetType,
      targetId: currentChat.id,
      lastId: lastMessageId,
      count: PAGE_SIZE,
      direction,
    };

    getMessages(data, (payload) => {
      // If payload has less then 20 messages, we don't have more messages to fetch
      setState((prev) => ({
        ...prev,
        [direction]: payload?.length === PAGE_SIZE,
        loadOnScroll: direction === historyDirection.after ? payload?.length < PAGE_SIZE : prev.loadOnScroll,
      }));

      if (payload?.length === 0) setState((prev) => ({ ...prev, loading: false }));
      if (callback) callback(payload);
      else {
        // The payload contains the history messages. Updating the store will affect the messages
        // Loading state will be updated after we add these messages to the state
        onFetch && onFetch(payload, { targetType: data.targetType, targetId: data.targetId });
      }
    });
  };

  return {
    messages,
    state,
    setState,
    setPageIndex,
    setMessages,
    fetchMessages,
  };
};
