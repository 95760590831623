export const permissionsModalContainer = {
  '.main-text': {
    textAlign: 'center',
    fontSize: 14,
  },
  ul: {
    padding: '1rem 2rem',
    li: {
      color: 'var(--secondary)',
    },
  },
};
