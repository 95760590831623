import { commonStyles } from 'styles';

export const tooltipContainer = {
  position: 'relative',
  display: 'inline-block',
  textAlign: 'center',
  cursor: 'pointer',
  outline: 'none',
};

export const popupTooltip = (props) => {
  const { singleLine, backgroundColor, color, border, borderRadius, noPadding } = props;

  return [
    {
      padding: noPadding ? 0 : '1em 0.8em',
      backgroundColor: backgroundColor ? `var(--${backgroundColor}, ${backgroundColor})` : 'var(--gray-200)',
      color: color ? `var(--${color}, ${color})` : 'var(--gray-400)',
      whiteSpace: singleLine ? 'nowrap' : 'normal',
      boxShadow: 'var(--box-shadow-light)',
      borderRadius: borderRadius || 8,
    },
    border && {
      border: border,
    },
    ...commonStyles(props),
  ];
};

export const arrow = ({ placement = 'right' }) => [
  {
    position: 'absolute',
    background: 'inherit',

    '&::before': {
      content: "''",
      transform: 'rotate(45deg)',
      position: 'absolute',
      width: 8,
      height: 8,
      background: 'inherit',
    },
  },

  placement.includes('top') && {
    bottom: 4,
  },

  placement.includes('bottom') && {
    top: -4,
  },

  placement.includes('left') && {
    right: 4,
  },

  placement.includes('right') && {
    left: -4,
  },
];
