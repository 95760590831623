/* eslint-disable no-unreachable */
import { useEffect, useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { SearchInput } from 'components/shared';
import { chatTypes } from 'enums';
import { ConversationListItem, CreateChatModal, CreateGroupModal } from 'components/screens';
import { setCurrentChatById, useChatStore, resetUnreadMessages, useMessagesStore, getUserId } from 'stores';
import { getTimestampFromUuidv7 } from 'utils';
import { Icon, showSuccess } from 'components';
import { createDirectChat, createGroup } from 'services';
import { chatsContainer } from './styles';

const Chats = () => {
  const { getText } = useTranslations();
  const { chats, groups, currentChat } = useChatStore();
  const { messages } = useMessagesStore();
  const userId = getUserId();
  const [recentChats, setRecentChats] = useState([]);
  const [openedChatId, setOpenedChatId] = useState(currentChat?.id);
  const [searchText, setSearchText] = useState();
  const [openModal, setOpenModal] = useState({ group: false, chat: false });

  useEffect(() => {
    getRecentChats();
  }, [chats, groups, messages]);

  useEffect(() => {
    setOpenedChatId(currentChat?.id);
  }, [currentChat?.id]);

  const handleSearch = (val) => {
    setSearchText(val);
    getRecentChats({ search: val });
  };

  const filterChatsByTextAndMessages = (text) => {
    if (!text) return [...(chats ?? []).filter((el) => !!messages[el.id]?.messages?.length), ...(groups ?? [])];

    const filteredChats = [...(chats ?? [])].filter((el) => {
      const otherUser = el.users?.find((user) => user.id !== userId);
      return (
        otherUser?.email?.toLowerCase() === text.toLowerCase() ||
        otherUser?.firstName?.toLowerCase()?.includes(text.toLowerCase()) ||
        otherUser?.lastName?.toLowerCase()?.includes(text.toLowerCase()) ||
        otherUser?.username?.toLowerCase()?.includes(text.toLowerCase())
      );
    });

    const filteredGroups = [...(groups ?? [])].filter(
      (el) =>
        (el.name && el.name.toLowerCase().includes(text.toLowerCase())) ||
        el.users?.find((member) => member.email?.toLowerCase() === text.toLowerCase()),
    );

    return [...filteredChats, ...filteredGroups];
  };

  const sortChatsByLastMessage = (chatsArray) => {
    return chatsArray.sort((chatA, chatB) => {
      // Groups can be without messages
      if (!(messages[chatA.id]?.messages ?? [])[0]) return 1;
      if (!(messages[chatB.id]?.messages ?? [])[0]) return -1;

      const chatALastMessage = messages[chatA.id]?.messages[0] ?? {};
      const chatBLastMessage = messages[chatB.id]?.messages[0] ?? {};

      const timeA = chatALastMessage.messageId && getTimestampFromUuidv7(chatALastMessage.messageId);
      const timeB = chatBLastMessage.messageId && getTimestampFromUuidv7(chatBLastMessage.messageId);

      return timeB - timeA;
    });
  };

  const getRecentChats = (options) => {
    const search = options?.search ?? searchText;
    const filteredChats = filterChatsByTextAndMessages(search);
    const sortedChats = sortChatsByLastMessage(filteredChats);

    setRecentChats(sortedChats);
  };

  const handleOpenChat = (id, targetType) => {
    setCurrentChatById(id, targetType);
    resetUnreadMessages(id);
  };

  const handleOpenGroupModal = () => setOpenModal({ group: true });
  const handleOpenChatModal = () => setOpenModal({ chat: true });

  const handleCloseModal = () => setOpenModal({ chat: false, group: false });

  const handleCreateGroup = (data, resetForm) => {
    createGroup(data, (response) => {
      resetForm(!response.success);

      showSuccess('Successfully created a group');
      handleCloseModal();
    });
  };

  const handleCreateChat = (data, resetForm) => {
    if (!data?.user) return;
    const foundChat = chats?.find((chat) => chat.users.find((user) => user.id == data.user));
    if (!foundChat) {
      return createDirectChat(data, (response) => {
        resetForm(!response.success);
        setCurrentChatById(data.user, chatTypes.chat);
        handleCloseModal();
      });
    }
    handleOpenChat(foundChat.id, chatTypes.chat);
    handleCloseModal();
  };

  return (
    <>
      <div css={chatsContainer}>
        <div className="page-title">
          <h4>{getText('chats')}</h4>
          <div className="actions">
            <Icon iconName="person_add" color="secondary" onClick={handleOpenChatModal} />
            <Icon iconName="group_add" color="secondary" onClick={handleOpenGroupModal} />
          </div>
        </div>

        <SearchInput placeholder={getText('searchChats')} onChange={handleSearch} />

        <div className="conversations-container">
          {recentChats && recentChats.length ? (
            <>
              <h5>{getText('recent')}</h5>
              <ul>
                {recentChats?.map((chat) => {
                  return (
                    <ConversationListItem
                      key={chat.id}
                      chat={chat}
                      isSelected={openedChatId === chat.id}
                      onChatOpen={handleOpenChat}
                    />
                  );
                })}
              </ul>
            </>
          ) : (
            <div className="no-data">{getText('noChatsFound')}</div>
          )}
        </div>
      </div>
      <CreateGroupModal showModal={openModal.group} onCreate={handleCreateGroup} onClose={handleCloseModal} />
      <CreateChatModal showModal={openModal.chat} onCreate={handleCreateChat} onClose={handleCloseModal} />
    </>
  );
};

export default Chats;
