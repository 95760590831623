import { useStore, createStore, showError } from 'components';
import { chatTypes } from 'enums/chat';
import { getUserId } from './user';
import { getCacheVal, orderByTimestamp, setCacheVal, sortChannels } from 'utils';
import { CACHE_KEYS } from 'enums';
import { createDirectChat, getUsersData } from 'services';

const initialChatStore = {
  currentChat: null,
  chats: [],
  groups: [],
  channels: [],
  otherChannels: [],
};

const chatStore = createStore(initialChatStore);

export const cacheChatsData = () => {
  const store = chatStore.getState();
  const userId = getUserId();

  setCacheVal(`${CACHE_KEYS.chats}-${userId}`, store);
};

export const initChatsFromCache = async (chatToOpen) => {
  const userId = getUserId();
  const cachedStore = getCacheVal(`${CACHE_KEYS.chats}-${userId}`);

  if (!cachedStore) return chatStore.setState({ ...initialChatStore, currentChat: chatToOpen ?? null });
  if (!chatToOpen || chatToOpen.id === userId) return chatStore.setState({ ...cachedStore });

  // Update chat to open using cached data.
  let updatedChatToOpen = null;
  if (chatToOpen.targetType === chatTypes.chat) {
    updatedChatToOpen = cachedStore.chats?.find(
      (chat) => chat.id === chatToOpen.id || chat.users.find((user) => user.id === chatToOpen.id),
    );
  } else if (chatToOpen.targetType === chatTypes.group)
    updatedChatToOpen = cachedStore.groups?.find((chat) => chat.id === chatToOpen.id);
  else if (chatToOpen.targetType === chatTypes.channel) {
    updatedChatToOpen =
      cachedStore.channels?.find((chat) => chat.id === chatToOpen.id) ??
      cachedStore.otherChannels?.find((chat) => chat.id === chatToOpen.id);
  }

  return chatStore.setState({
    ...cachedStore,
    currentChat: updatedChatToOpen ?? chatToOpen,
  });
};

// Called on "init" message received from websocket
export const updateAllChats = async (payload) => {
  const { currentChat } = chatStore.getState();
  const sortedChats = (payload.chats ?? [])
    .map((chat) => ({ ...chat, targetType: chatTypes.chat }))
    .sort((chatA, chatB) => orderByTimestamp(chatA.id, chatB.id));

  const sortedGroups = (payload.groups ?? [])
    .map((group) => ({ ...group, targetType: chatTypes.group }))
    .sort((groupA, groupB) => orderByTimestamp(groupA.id, groupB.id));

  // Private and public channels, in which the current user is a member
  const sortedChannels = sortChannels(payload.channels)?.map((channel) => ({
    ...channel,
    targetType: chatTypes.channel,
  }));

  // Public channels, in which the current user is NOT a member, but he/she can join
  const sortedOtherChannels = (payload.public_channels ?? [])
    .map((channel) => ({ ...channel, targetType: chatTypes.channel }))
    .sort((channelA, channelB) => orderByTimestamp(channelA.id, channelB.id));

  if (!currentChat) {
    chatStore.setState((prev) => ({
      ...prev,
      chats: sortedChats,
      groups: sortedGroups,
      channels: sortedChannels,
      otherChannels: sortedOtherChannels,
    }));
    return cacheChatsData();
  }

  // Update current chat after init message from WS. There might be changes in name, members or status.
  let updatedCurrentChat = null;
  if (currentChat.targetType === chatTypes.chat) {
    updatedCurrentChat = sortedChats?.find(
      (chat) => chat.id === currentChat.id || chat.users.find((user) => user.id === currentChat.id),
    );

    if (!updatedCurrentChat) {
      const [res, err] = await getUsersData({ users: [currentChat.id] });
      if (err) {
        showError('The user does not exist');
        updatedCurrentChat = null;
      }
      // Create chat only if user is found or added to BE cache
      if (res && res[0]) {
        updatedCurrentChat = { ...currentChat, users: [res[0]] };
        createDirectChat({ user: currentChat.id });
      }
    }
  } else if (currentChat.targetType === chatTypes.group) {
    updatedCurrentChat = sortedGroups?.find((chat) => chat.id === currentChat.id);
    !updatedCurrentChat && showError('The group does not exist or you are not allowed to open it');
  } else if (currentChat.targetType === chatTypes.channel) {
    updatedCurrentChat =
      sortedChannels?.find((chat) => chat.id === currentChat.id) ??
      sortedOtherChannels?.find((chat) => chat.id === currentChat.id);
    !updatedCurrentChat && showError('The channel does not exist or you are not allowe to open it');
  }

  chatStore.setState((prev) => ({
    ...prev,
    chats: sortedChats,
    groups: sortedGroups,
    channels: sortedChannels,
    otherChannels: sortedOtherChannels,
    currentChat: updatedCurrentChat ?? null,
  }));
};

// Add new direct chat to storage.
// Update current chat if the current user has opened this chat.
// When current chat is saved but not found in store, we save it as { id: user.id , targetType }, where user.id is the id of the other user
export const addChat = (payload) => {
  const { currentChat } = chatStore.getState();
  const chatToAdd = {
    ...payload,
    targetType: chatTypes.chat,
  };

  chatStore.setState((prev) => ({
    ...prev,
    chats: [...(prev.chats ?? []), chatToAdd],
    currentChat:
      currentChat?.targetType === chatTypes.chat && payload.users.find((user) => user.id === currentChat?.id)
        ? chatToAdd
        : prev.currentChat,
  }));
};

export const addChannel = (data, fromCurrent) => {
  const { channels, otherChannels, currentChat } = chatStore.getState();
  const userId = getUserId();

  if (fromCurrent && currentChat?.id === data.id) {
    const channelData = {
      ...currentChat,
      users: [...currentChat.users, data.user],
    };

    chatStore.setState((prev) => ({
      ...prev,
      channels: sortChannels([channelData, ...(prev.channels ?? [])]),
      currentChat: channelData,
    }));
  } else {
    if (channels.find((channel) => channel.id === data.id) || otherChannels.find((channel) => channel.id === data.id))
      return;

    const isMember = data.users.find((user) => user.id === userId);
    const isOwner = data.owner.id === userId;

    const channelToAdd = { ...data, targetType: chatTypes.channel };
    chatStore.setState((prev) => ({
      ...prev,
      channels: isMember ? sortChannels([channelToAdd, ...(prev.channels ?? [])]) : prev.channels,
      otherChannels: !isMember ? [channelToAdd, ...(prev.otherChannels ?? [])] : prev.otherChannels,
      currentChat: isOwner ? channelToAdd : prev.currentChat,
    }));
  }
};

// When you leave or join a public channel, you should move the channel in the corresponding collection
// If you LEAVE the channel (toMyChannels === false), the channel will go from MY channels to OTHER channels
// If you JOIN the channel (toMyChannels === true), the channel will go from OTHER channels to MY channels
export const movePublicChannel = (data, toMyChannels) => {
  const { channels, otherChannels } = chatStore.getState();
  const foundChannel = (toMyChannels ? otherChannels : channels).find((channel) => channel.id === data.id);
  if (!foundChannel) return;

  foundChannel.users = toMyChannels
    ? [...foundChannel.users, data.user]
    : foundChannel.users.filter((user) => user.id !== data.user.id);

  chatStore.setState((prev) => ({
    ...prev,
    channels: toMyChannels
      ? sortChannels([...(prev.channels ?? []), foundChannel])
      : [...prev.channels.filter((ch) => ch.id !== data.id)],
    otherChannels: !toMyChannels
      ? sortChannels([...(prev.otherChannels ?? []), foundChannel])
      : [...prev.otherChannels.filter((ch) => ch.id !== data.id)],
    currentChat: data.id === prev.currentChat?.id ? { ...foundChannel } : prev.currentChat,
  }));
};

export const addGroup = (data) => {
  const { groups } = chatStore.getState();
  const userId = getUserId();

  if (groups.find((group) => group.id === data.id)) return;

  const isOwner = data.owner.id === userId;

  const groupToAdd = { ...data, targetType: chatTypes.group };
  chatStore.setState((prev) => ({
    ...prev,
    groups: [groupToAdd, ...(prev.groups ?? [])],
    currentChat: isOwner ? groupToAdd : prev.currentChat,
  }));
};

export const getChannelById = (id) => {
  const { channels, otherChannels } = chatStore.getState();
  return channels.find((ch) => ch.id === id) || otherChannels.find((ch) => ch.id === id) || {};
};

export const setCurrentChatById = (id, targetType) => {
  const { currentChat, chats, groups, channels, otherChannels } = chatStore.getState();

  if (!id) return chatStore.setState((prev) => ({ ...prev, currentChat: null }));

  if (currentChat?.id === id) return;

  let chatToOpen = null;
  switch (targetType) {
    case chatTypes.chat: {
      //if we don't find this chat, then we should save the chat with currentChat.id = user.id. This will mark this chat for update on createdChat received
      chatToOpen = chats.find((chat) => chat.id === id || chat.users.find((user) => user.id === id)) ?? {
        id,
        targetType: chatTypes.chat,
      };
      break;
    }
    case chatTypes.group: {
      chatToOpen = groups.find((group) => group.id === id);
      break;
    }
    case chatTypes.channel: {
      chatToOpen = channels.find((channel) => channel.id === id) ?? otherChannels.find((channel) => channel.id === id);
      break;
    }
  }

  chatStore.setState((prev) => ({ ...prev, currentChat: chatToOpen ? { ...chatToOpen } : null }));
};

export const setCurrentChat = (chatData) => {
  if (!chatData) return;

  chatStore.setState((prev) => ({ ...prev, currentChat: chatData }));
};

export const addUsersToChannelStore = (data) => {
  const { channels = [], otherChannels = [], currentChat } = chatStore.getState();
  const userId = getUserId();
  const foundChannel = {
    ...(channels.find((channel) => channel.id === data.id) ??
      otherChannels.find((channel) => channel.id === data.id) ??
      {}),
  };

  if (!foundChannel) return;
  const isCurrent = currentChat?.id === data.id;
  const usersToAdd = data.users.filter(
    (user) => !foundChannel?.users?.find((channelUser) => channelUser.id === user.id),
  );
  const isMember = foundChannel.users?.find((channelUser) => channelUser.id === userId);

  const updatedChannels = (isMember ? channels : otherChannels).map((channel) => {
    if (channel.id === foundChannel.id) channel.users = [...channel.users, ...usersToAdd];

    return channel;
  });

  chatStore.setState((prev) => ({
    ...prev,
    channels: isMember ? updatedChannels : prev.channels,
    otherChannels: !isMember ? updatedChannels : prev.otherChannels,
    currentChat: isCurrent ? { ...prev.currentChat, users: [...foundChannel.users, ...usersToAdd] } : prev.currentChat,
  }));
};

export const addUsersToGroupStore = (data) => {
  const { groups, currentChat } = chatStore.getState();
  const foundGroup = groups.find((group) => group.id === data.id);
  if (!foundGroup) return;
  const isCurrent = currentChat?.id === data.id;

  const usersToAdd = data.users.filter((user) => !currentChat?.users?.find((groupUser) => groupUser.id === user.id));

  const updatedGroups = groups.map((group) => {
    if (group.id === foundGroup.id) group.users = [...group.users, ...usersToAdd];

    return group;
  });

  chatStore.setState((prev) => ({
    ...prev,
    groups: updatedGroups,
    currentChat: isCurrent ? { ...prev.currentChat, users: [...foundGroup.users, ...usersToAdd] } : prev.currentChat,
  }));
};

export const removeUsersFromGroupStore = (data) => {
  const { groups, currentChat } = chatStore.getState();
  const foundGroup = groups.find((group) => group.id === data.id);
  if (!foundGroup) return;

  const isCurrent = currentChat?.id === data.id;
  const updatedUsers = [...(foundGroup.users?.filter((groupUser) => !data.users.includes(groupUser.id)) ?? [])];

  const updatedGroups = [...groups].map((group) => {
    if (group.id === foundGroup.id) group.users = updatedUsers;

    return group;
  });

  chatStore.setState((prev) => ({
    ...prev,
    groups: updatedGroups,
    currentChat: isCurrent ? { ...prev.currentChat, users: [...updatedUsers] } : prev.currentChat,
  }));
};

export const removeUsersFromChannelStore = (data) => {
  const { channels, currentChat } = chatStore.getState();
  const foundChannel = channels.find((channel) => channel.id === data.id);
  if (!foundChannel) return;

  const isCurrent = currentChat?.id === data.id;
  const updatedUsers = [...(foundChannel.users?.filter((channelUser) => !data.users.includes(channelUser.id)) ?? [])];

  const updatedChannels = [...channels].map((channel) => {
    if (channel.id === foundChannel.id) channel.users = updatedUsers;

    return channel;
  });

  chatStore.setState((prev) => ({
    ...prev,
    channels: [...updatedChannels],
    currentChat: isCurrent ? { ...prev.currentChat, users: [...updatedUsers] } : prev.currentChat,
  }));
};

export const removeGroup = (id) => {
  const { groups, currentChat } = chatStore.getState();
  const isCurrent = id === currentChat?.id;

  chatStore.setState((prev) => ({
    ...prev,
    groups: groups.filter((group) => group.id !== id),
    currentChat: isCurrent ? null : prev.currentChat,
  }));
};

export const removeChannel = (id, isAdmin) => {
  const { channels = [], otherChannels = [], currentChat = {} } = chatStore.getState();
  const userId = getUserId();
  const isCurrent = id === currentChat.id;

  chatStore.setState((prev) => ({
    ...prev,
    channels: channels.filter((channel) => channel.id !== id),
    otherChannels: otherChannels.filter((channel) => channel.id !== id),
    currentChat: isCurrent
      ? isAdmin
        ? { ...(currentChat ?? {}), users: [...(currentChat?.users?.filter((user) => user.id !== userId) ?? [])] }
        : null
      : prev.currentChat,
  }));
};

// ROLES: Can admin update a channel, in which he doesn't participate?
export const editChannel = (data) => {
  const { channels, currentChat } = chatStore.getState();
  const foundChannel = channels.find((channel) => channel.id === data.id);

  if (!foundChannel) return;

  const editedChannelData = {
    ...data,
    name: data.name ?? foundChannel.name,
    targetType: chatTypes.channel,
    users: [...foundChannel.users],
    owner: foundChannel.owner,
  };

  const updatedChannels = channels.map((channel) => {
    if (channel.id === foundChannel.id) return editedChannelData;
    return channel;
  });

  chatStore.setState((prev) => ({
    ...prev,
    channels: updatedChannels,
    currentChat: data.id === currentChat?.id ? editedChannelData : prev.currentChat,
  }));
};

export const useChatStore = (...args) => useStore(chatStore, ...args);
