import { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Button, Form, Icon, Modal, UsersSearch } from 'components';
import { createChatContainer } from './styles';
import { getUserId } from 'stores';

const CreateChatModal = (props) => {
  const { showModal, onClose, onCreate } = props;
  const { getText } = useTranslations();
  const [user, setUser] = useState({});
  const userId = getUserId();

  const handleCreateChat = (val, resetForm) => {
    onCreate(val, resetForm);
    setUser(null);
  };

  const handleClose = () => {
    onClose();
    setUser(null);
  };

  const handleFilterUsers = useCallback((users) => users.filter((member) => member?.id !== userId));

  return (
    <Modal show={showModal} onClose={onClose} css={createChatContainer}>
      <Form className="chat-modal-container" values={user} onSubmit={handleCreateChat}>
        <div className="modal-title">
          <h4>{getText('openDirectChat')}</h4>
          <Icon iconName="close" size={16} onClick={handleClose} />
        </div>

        <div className="modal-body">
          {({ setValues }) => (
            <div className="members-container">
              <UsersSearch
                label={getText('searchForUser')}
                users={[]}
                filterUsers={handleFilterUsers}
                onChangeSelection={(selected) => setValues({ user: selected })}
              />
            </div>
          )}
        </div>

        <div className="action-container">
          {({ onSubmit }) => (
            <>
              <Button small type="secondary" className="close-button" onClick={handleClose}>
                {getText('close')}
              </Button>
              <Button small onClick={onSubmit}>
                {getText('openChat')}
              </Button>
            </>
          )}
        </div>
      </Form>
    </Modal>
  );
};

CreateChatModal.propTypes = {
  showModal: PropTypes.bool,
  onCreate: PropTypes.func,
  onClose: PropTypes.func,
};

export default CreateChatModal;
