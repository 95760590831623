import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { ConversationListItem } from 'components';
import { useChatStore, useMessagesStore } from 'stores';
import { getTimestampFromUuidv7 } from 'utils';

const MyChannelsList = (props) => {
  const { searchText, selected, onSelect } = props;
  const { getText } = useTranslations();
  const { messages } = useMessagesStore();
  const { channels, otherChannels } = useChatStore();

  const [allChannels, setAllChannels] = useState({
    myChannels: [],
    joinablePublicChannels: [],
  });
  useEffect(() => {
    getMyChannels();
  }, [channels, otherChannels, messages]);

  useEffect(() => {
    getMyChannels({ search: searchText });
  }, [searchText]);

  useEffect(() => {
    setAllChannels((prev) => ({
      ...prev,
      myChannels: sortChannelsByLastMessage(prev.myChannels),
    }));
  }, [messages]);

  const getMyChannels = (options) => {
    const search = options?.search ?? searchText;
    const filteredChannels = filterChannelsByText(search);
    const sortedChannels = {
      myChannels: sortChannelsByLastMessage(filteredChannels.myChannels),
      joinablePublicChannels: filteredChannels.joinablePublicChannels,
    };

    setAllChannels(sortedChannels);
  };

  const filterChannelsByText = (text) => {
    return text
      ? {
          myChannels: channels.filter((channel) => channel.name.toLowerCase().includes(text.toLowerCase())),
          joinablePublicChannels: otherChannels.filter((channel) =>
            channel.name.toLowerCase().includes(text.toLowerCase()),
          ),
        }
      : {
          myChannels: channels,
          joinablePublicChannels: otherChannels,
        };
  };

  const sortChannelsByLastMessage = (channelsArray) => {
    return channelsArray.sort((channelA, channelB) => {
      // Not every channel has messages
      if (!(messages[channelA.id]?.messages ?? [])[0]) return 1;
      if (!(messages[channelB.id]?.messages ?? [])[0]) return -1;

      const channelALastMessage = messages[channelA.id].messages[0] ?? {};
      const channelBLastMessage = messages[channelB.id].messages[0] ?? {};

      const timeA = channelALastMessage.messageId && getTimestampFromUuidv7(channelALastMessage.messageId);
      const timeB = channelBLastMessage.messageId && getTimestampFromUuidv7(channelBLastMessage.messageId);

      return timeB - timeA;
    });
  };

  return allChannels.myChannels.length || allChannels.joinablePublicChannels.length ? (
    <ul>
      {allChannels.myChannels?.map((channel) => (
        <ConversationListItem
          key={channel.id}
          chat={channel}
          isSelected={selected === channel.id}
          onChatOpen={onSelect}
        />
      ))}
      {allChannels.joinablePublicChannels?.map((channel) => (
        <ConversationListItem
          noMessage
          key={channel.id}
          chat={channel}
          isMember={false}
          isSelected={selected === channel.id}
          onChatOpen={onSelect}
        />
      ))}
    </ul>
  ) : (
    <div className="no-data">{getText('noChannelsFound')}</div>
  );
};

MyChannelsList.propTypes = {
  searchText: PropTypes.string,
  selected: PropTypes.string,
  onSelect: PropTypes.func,
};

export default MyChannelsList;
