import { useEffect, useState } from 'react';
import { useTranslations } from '@veraio/strank';
import { hasAtLeastOneRole, hasRole, USER_ROLES } from '@oneecosystem/authenticate';
import { deleteAllChatMessages, getUserId, setCurrentChatById, setSelectedMessage, useChatStore } from 'stores';
import {
  Avatar,
  Button,
  ChatActions,
  ChatInfo,
  ConfirmationModal,
  ConversationContainer,
  Icon,
  SendMessage,
  Spinner,
} from 'components';
import { chatContainer, chatHeaderContainer } from './styles';
import { userChatStatusMap, chatTypes } from 'enums';
import { getFirstSymbol, getUserDisplayName } from 'utils';
import { joinChannel, sendChatMessage } from 'services';
import { isEmpty } from '@veraio/core';

const Chat = ({ styles }) => {
  const { getText } = useTranslations();
  const { currentChat } = useChatStore();
  const userId = getUserId();
  const [myCurrentChat, setMyCurrentChat] = useState(null);
  const [openContainer, setOpenContainer] = useState({ chatInfo: false });
  const [openModal, setOpenModal] = useState({ join: false });
  const [penpal, setPenpal] = useState(null);
  const isModerator = hasRole(USER_ROLES.CHAT_MODERATOR);
  const isAdmin = hasAtLeastOneRole([USER_ROLES.CHAT_SUPER_ADMIN, USER_ROLES.CHAT_ADMIN]);

  useEffect(() => {
    setMyCurrentChat(currentChat);
    currentChat?.targetType === chatTypes.chat &&
      setPenpal(currentChat?.users?.find((user) => user.id !== userId) ?? {});
  }, [currentChat]);

  const handleToggleChatInfo = () => setOpenContainer((prev) => ({ ...prev, chatInfo: !prev.chatInfo }));

  const closeChat = () => setCurrentChatById(null);

  const handleSendMessage = (message) => {
    if (message.text && !message.text.length) return;

    const messageData = {
      content: message.text,
      targetType: myCurrentChat.targetType,
      targetId: myCurrentChat.id,
    };

    sendChatMessage(messageData);
  };

  const handleJoinChannel = () => {
    joinChannel(myCurrentChat.id, (response) => {
      if (response.success) deleteAllChatMessages(myCurrentChat.id);
      handleCloseModal();
    });
  };

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setOpenModal({ join: true });
  };

  const handleCloseModal = () => setOpenModal({ join: false });

  return (
    <div css={chatContainer(styles, !!myCurrentChat?.id)}>
      {myCurrentChat?.id ? (
        <>
          <div
            css={chatHeaderContainer(
              myCurrentChat.targetType === chatTypes.chat && userChatStatusMap[penpal?.status]?.color,
            )}>
            {myCurrentChat?.name || !isEmpty(penpal) ? (
              <>
                <div className="chat-title">
                  <Icon iconName="chevron_left" onClick={closeChat} className="go-back-icon" />
                  {myCurrentChat.targetType === chatTypes.group ||
                  (myCurrentChat.targetType === chatTypes.channel && !myCurrentChat.icon) ? (
                    <div className="letter-avatar">
                      <div>{getFirstSymbol(myCurrentChat.name, myCurrentChat.targetType[0])}</div>
                    </div>
                  ) : myCurrentChat.targetType === chatTypes.channel ? (
                    <Avatar picThumbnailUrl={myCurrentChat.icon} />
                  ) : (
                    <Avatar picThumbnailUrl={penpal?.pictureUrl} />
                  )}
                  <div className="chat-name" role="button" tabIndex={0} onClick={handleToggleChatInfo}>
                    {myCurrentChat?.name ?? getUserDisplayName(penpal)}
                  </div>
                  {myCurrentChat.targetType === chatTypes.chat && <div className="status-indicator" />}
                </div>
                <ChatActions chat={myCurrentChat} onOpenChatInfo={handleToggleChatInfo} onSelect={setSelectedMessage} />
              </>
            ) : (
              <Spinner loading={!myCurrentChat?.name || isEmpty(penpal)} size={25} />
            )}
          </div>
          {isAdmin || myCurrentChat?.users?.find((user) => user.id === userId) ? (
            <ConversationContainer />
          ) : (
            <div className="empty-chat-container">
              <p className="not-allowed-text">{getText('joinChannelText')}</p>
            </div>
          )}

          {myCurrentChat?.targetType === chatTypes.channel &&
          myCurrentChat?.owner?.id !== userId &&
          !myCurrentChat?.users?.find((member) => member.id === userId) ? (
            <div className="restricted-chat-footer">
              {isAdmin && <p>{getText('joinOrRefreshChat')}</p>}
              <Button small type="default" onClick={handleOpenModal}>
                {getText('joinChat')}
              </Button>
            </div>
          ) : !isAdmin && !isModerator && myCurrentChat?.targetType === chatTypes.channel && myCurrentChat?.readOnly ? (
            <div className="restricted-chat-footer">
              <p>{getText('readOnlyChat')}</p>
            </div>
          ) : (
            <SendMessage onSend={handleSendMessage} />
          )}
        </>
      ) : (
        <div className="no-chat-container">
          <Icon iconName="sms" className="no-chat-icon" />
          <p className="welcome-text">{getText('welcome')}</p>
          <p className="secondary-text">{getText('findContactsGroupsChannels')}</p>
          <p className="secondary-text">{getText('connectWithMembersWorldwide')}</p>
        </div>
      )}
      {openContainer.chatInfo && (
        <div className="chat-info-container">
          <ChatInfo onClose={handleToggleChatInfo} />
        </div>
      )}
      <ConfirmationModal
        showModal={openModal.join}
        title={getText('joinChannel')}
        onOk={handleJoinChannel}
        onCancel={handleCloseModal}>
        {getText('confirmJoinChannel')}
      </ConfirmationModal>
    </div>
  );
};

export default Chat;
