export const addMembersModal = {
  '.members-modal-container': {
    backgroundColor: 'var(--background-stronger)',
    '.modal-title': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 1.5rem 2rem 1.5rem',
      borderBottom: '1px solid var(--border-color)',
    },

    '.modal-body': {
      padding: '2.75rem 2.5rem 1rem 2.5rem',
      '.upload-button': {
        '.button': {
          maxHeight: '3.8rem',
          color: 'var(--gray-100)',
        },
      },
      '.members-container': {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: '2rem',
        marginTop: '1rem',

        '.members-button': {
          fontSize: 13,
          width: '12rem',
          minHeight: '3.2rem',
          margin: '1rem 0',
        },
        '.suggestion': {
          justifyContent: 'space-between',
          '.user-info': {
            display: 'flex',
            alignItems: 'center',
            gap: 16,
          },
        },
      },
    },

    '.action-container': {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '2rem 1.5rem 0 1.5rem',
      borderTop: '1px solid var(--border-color)',
      '.close-button': {
        marginRight: 12,
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
};
