import { omit, useStore } from '@veraio/core';
import { createStore } from 'components';
import { CACHE_KEYS } from 'enums';
import { getCacheVal, getTimestampFromUuidv7, setCacheVal } from 'utils';
import { getUserId } from './user';

const initialMessagesStore = {
  messages: {},
  editModeMessage: null,
  selectedMessage: null,
};

const messagesStore = createStore(initialMessagesStore);

export const cacheMessagesData = () => {
  const store = messagesStore.getState();
  const userId = getUserId();
  const objectToCache = {};

  Object.entries(store.messages ?? {}).forEach(([chatId, messagesData]) => {
    if (!messagesData?.noCache) objectToCache[chatId] = messagesData;
  });

  setCacheVal(`${CACHE_KEYS.messages}-${userId}`, { messages: objectToCache });
};

// Get messages from cache if any, otherwise stay with initial store
export const initMessagesFromCache = () => {
  const userId = getUserId();
  const cachedData = getCacheVal(`${CACHE_KEYS.messages}-${userId}`);

  messagesStore.setState({ ...(cachedData ?? initialMessagesStore), editModeMessage: null });
};

// Triggered when messages where received while offline
// Update stored messages and add new messages
export const catchupMessages = (payload) => {
  const { chats, groups, channels } = payload;

  const preparedChatsMsgs = chats?.length && prepareMessages(chats, true);
  const preparedGroupsMsgs = groups?.length && prepareMessages(groups, true);
  const preparedChannelsMsgs = channels?.length && prepareMessages(channels, true);

  messagesStore.setState((prev) => ({
    ...prev,
    messages: {
      ...prev.messages,
      ...(preparedChatsMsgs ?? {}),
      ...(preparedGroupsMsgs ?? {}),
      ...(preparedChannelsMsgs ?? {}),
    },
  }));
  cacheMessagesData();
};

// Add new message for given chat
export const addMessage = (data) => {
  const { messages } = messagesStore.getState();
  const userId = getUserId();

  // console.log('%c Add message', 'color:green; font-size:20px');

  const { targetId, author } = data;
  let unreadMessagesCount = messages[targetId]?.unreadCount ?? 0;

  if (author.id === userId) unreadMessagesCount = 0;
  else unreadMessagesCount++;

  const chatMessages = [data, ...(messages[targetId]?.messages ?? [])].sort((msgA, msgB) =>
    orderByTimestamp(msgA.messageId, msgB.messageId, false),
  );

  messagesStore.setState((prev) => ({
    ...prev,
    messages: {
      ...prev.messages,
      [targetId]: {
        unreadCount: unreadMessagesCount,
        messages: chatMessages,
      },
    },
  }));
};

// Add many messages for given chat
export const addChatMessages = (data, chatData, noCache) => {
  const preparedMessagesByChat = prepareMessages(
    [{ id: chatData.targetId, messages: data }],
    false,
    chatData.targetType,
    noCache,
  );

  messagesStore.setState((prev) => ({
    ...prev,
    messages: {
      ...prev.messages,
      ...preparedMessagesByChat,
    },
  }));
};

export const resetUnreadMessages = (id) => {
  const { messages } = messagesStore.getState();
  const foundMessages = messages[id];

  if (!foundMessages) return;

  messagesStore.setState((prev) => ({
    ...prev,
    messages: {
      ...prev.messages,
      [id]: {
        ...(prev.messages[id] ?? {}),
        unreadCount: 0,
      },
    },
  }));
};

export const deleteAllChatMessages = (targetId) => {
  messagesStore.setState((prev) => ({
    editModeMessage: prev.editModeMessage?.targetId === targetId ? null : prev.editModeMessage,
    messages: { ...omit(prev.messages, targetId) },
  }));
};

export const updateMessage = (messageData) => {
  const { messages } = messagesStore.getState();
  const updatedMessages = (messages[messageData.targetId]?.messages ?? []).map((msg) => {
    if (msg.messageId === messageData.messageId) {
      const updated = { ...msg, ...messageData };
      return updated;
    } else return msg;
  });

  messagesStore.setState((prev) => ({
    editModeMessage: prev.editModeMessage?.messageId === messageData.messageId ? null : prev.editModeMessage,
    messages: {
      ...prev.messages,
      [messageData.targetId]: {
        unreadCount: prev.messages[messageData.targetId]?.unreadCount ?? 0,
        messages: [...updatedMessages],
      },
    },
  }));
};

export const deleteMessage = (messageData) => {
  const { messages } = messagesStore.getState();
  let deletedIndex = null;

  const storedMessages = messages[messageData.targetId];
  const updatedMessages = (storedMessages?.messages ?? []).filter((msg, index) => {
    if (msg.messageId === messageData.messageId) deletedIndex = index;
    return msg.messageId !== messageData.messageId;
  });

  const updatedUnreadCount =
    deletedIndex > storedMessages?.unreadCount - 1 ? storedMessages?.unreadCount ?? 0 : storedMessages?.unreadCount - 1;

  messagesStore.setState((prev) => ({
    editModeMessage: prev.editModeMessage?.messageId === messageData.messageId ? null : prev.editModeMessage,
    messages: {
      ...prev.messages,
      [messageData.targetId]: {
        unreadCount: updatedUnreadCount,
        messages: [...updatedMessages],
      },
    },
  }));
};

export const setEditModeMessage = (messageData) => {
  messagesStore.setState((prev) => ({
    messages: prev.messages,
    editModeMessage: messageData,
  }));
};

export const setSelectedMessage = (selectedMessage) => {
  messagesStore.setState((prev) => ({ ...prev, selectedMessage }));
};

// Prepare messages before add:
//  - if message exists - update the existing message
//  - if message does not exist - add it to collection
const prepareMessages = (chats = [], addToUnread, targetType, noCache) => {
  const { messages } = messagesStore.getState();
  const messagesObj = {};

  chats.forEach((chat) => {
    const storedMessages = { ...(messages[chat.id] ?? {}) };

    const filteredMessages = storedMessages.messages?.filter(
      (msg) => !chat.messages?.find((m) => m.messageId === msg.messageId),
    );

    const mappedMessages = targetType
      ? chat.messages?.map((msg) => ({ ...msg, targetId: chat.id, targetType })) ?? []
      : chat.messages ?? [];

    const sortedMessages = [...mappedMessages, ...(filteredMessages ?? [])].sort((msgA, msgB) =>
      orderByTimestamp(msgA.messageId, msgB.messageId),
    );
    messagesObj[chat.id] = {
      ...(noCache && { noCache }),
      unreadCount: (storedMessages.unreadCount ?? 0) + (addToUnread ? chat.messages.length : 0),
      messages: [...sortedMessages],
    };
  });

  return messagesObj;
};

const orderByTimestamp = (uuidA, uuidB, asc) => {
  const timeA = uuidA ? getTimestampFromUuidv7(uuidA) : 0;
  const timeB = uuidB ? getTimestampFromUuidv7(uuidB) : 0;

  return asc ? timeA - timeB : timeB - timeA;
};

export const useMessagesStore = (...args) => useStore(messagesStore, ...args);
