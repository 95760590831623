export const inputField = (hasValidation, hasError, { disabled, small, leftIcon, type }) => [
  {
    color: 'var(--secondary)',
    border: '1px solid var(--border-color)',
    backgroundColor: 'var(--gray-500)',
    borderRadius: '0.4rem',
    minHeight: small ? '3.6rem' : '4.8rem',
    padding: small ? '0.5em 1em' : '0.7em 1em',
    width: '100%',
    marginBottom: hasValidation ? 6 : 3,
    outline: 'none',

    '&::placeholder': {
      fontSize: 13,
      color: 'var(--secondary-weaker)',
    },
  },
  hasError && {
    '&::placeholder': {
      color: 'var(--secondary-weaker)',
    },
  },
  leftIcon && {
    paddingLeft: `${(leftIcon?.size ?? 2) + 0.6}em`,
  },
  disabled
    ? {
      borderColor: 'var(--background-weak)',
      color: 'var(--gray-500)',
      backgroundColor: 'var(--gray-900)',
        cursor: 'not-allowed',

        '&::placeholder': {
          color: 'var(--gray)',
        },
      }
    : {
        '&:focus': {
          borderColor: 'var(--secondary)',
          outline: 'none',

          '&::placeholder': {
            color: 'var(--secondary-weaker)',
          },
        },
      },
  type === 'textarea' && {
      resize: 'none'
  },
];
