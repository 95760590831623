export const phoneInputContainer = (hasValidation) => ({
  display: 'flex',
  width: '100%',
  marginBottom: hasValidation ? 6 : 0,

  '.phone-input': {
    height: 52,
    paddingTop: 16,
    color: 'var(--secondary)',
    border: '1px solid var(--border-color)',
    backgroundColor: 'var(--gray-500)',
    borderRadius: '0.8rem',
  },
});

export const phoneInputCountrySelect = {
  flex: '1 0 100px',
};

export const countryContainer = {
  display: 'flex',
  padding: 8,
};

export const countryListLabel = {
  marginLeft: 12,
};

export const countryImage = {
  marginRight: 12,
  width: 20,
  height: 20,
};
