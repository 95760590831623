export const chatBodyContainer = {
  flexBasis: '100%',
  display: 'flex',
  flexDirection: 'column-reverse',
  padding: '1.5rem',
  overflow: 'auto',
  borderTop: '1px solid var(--border-color-weak)',
  borderBottom: '1px solid var(--border-color-weak)',

  '.load-more-container': {
    textAlign: 'center',
  },

  '.no-messages': {
    textAlign: 'center',
    color: 'var(--secondary)'
  },

  '.new-messages': {
    position: 'absolute',
    top: 100,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    textAlign: 'center',
    zIndex: 19,
    '.new-messages-button': {
      display: 'flex',
      padding: '4px 12px',
      alignItems: 'center',
      borderRadius: '4px',
      background: 'var(--background-weak)',
      opacity: 0.8,
      cursor: 'pointer',
      ':hover': {
        opacity: 1,
      },
    },
  },

  '.reset-messages': {
    position: 'absolute',
    bottom: 100,
    right: 20,
    zIndex: 19,
    textAlign: 'center',
    background: 'var(--background-weak)',
    opacity: 0.8,
    borderRadius: '50%',
    padding: '0.8rem',
    cursor: 'pointer',
    width: '4rem',
    height: '4rem',
    ':hover': {
      opacity: 1,
    },
  },

  '.chat-day-title': {
    margin: '1rem -1.5rem',
    position: 'relative',
    textAlign: 'center',
    '.title': {
      backgroundColor: 'var(--border-color)',
      padding: '0.5rem 1.25rem',
      borderRadius: '5px',
      position: 'relative',
      zIndex: 9,
      color: 'var(--secondary)',
      fontSize: 13,
    },
    '::before': {
      content: '""',
      height: '1px',
      backgroundColor: 'var(--border-color)',
      position: 'absolute',
      right: 0,
      top: '10px',
      width: '100%',
    },
  },

  '.empty-chat-container': {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    color: 'var(--secondary-weaker)',
    '.no-messages-text': {
      fontWeight: 600,
    },
    '.start-chat-text': {
      fontSize: 13,
    },
  },
};

export const messageContainer = (isRight, isHighlighted) => ({
  display: 'flex',
  alignItems: 'flex-end',
  flexDirection: isRight ? 'row-reverse' : 'row',
  backgroundColor: isHighlighted ? 'var(--primary-weaker)' : 'transparent',
  transition: 'background-color 400ms linear',
  padding: '1.5rem 0',
  marginRight: !isRight ? '2.75rem' : 0,
  marginLeft: isRight ? '2.75rem' : 0,

  '.avatar-column': {
    minWidth: 50,
    maxWidth: 50,
    marginBottom: 0,
    textAlign: 'center',
  },
  '.message-column': {
    textAlign: isRight ? 'right' : 'left',
    position: 'relative',
    margin: 'auto 10px',

    '.message-box-item': {
      position: 'absolute',
      right: isRight && 0,
      borderStyle: 'solid',
      borderWidth: isRight ? '0 14px 14px 0' : '14px 14px 0 0',
      borderColor: isRight
        ? 'transparent var(--background-weak) transparent transparent'
        : 'var(--primary) transparent transparent transparent',
    },
    '.sender-name': {
      marginTop: 15,
      fontWeight: 500,
    },
  },
  '.deleted-message': {
    padding: '1rem',
    margin: '0.5rem 6rem',
    borderRadius: 8,
    border: '2px solid var(--background-weak)',
    color: 'var(--secondary)',
    background: 'transparent',
    fontSize: 12,
    cursor: 'pointer',
  },
});
