import { Route, Redirect, Switch, useHistory } from 'react-router-dom';
import { ROUTES } from 'enums';
import { Login } from '../authorization';
import NotFound from './NotFound';
import { publicContentContainer } from './styles';

export const PublicRoutes = () => {
  const history = useHistory();

  return (
    <section css={publicContentContainer}>
      <Switch>
        <Route exact path={ROUTES.Login} component={Login} />
        <Redirect exact from={'/*'} to={{ pathname: ROUTES.Login, state: { navigateTo: history?.location?.search } }} />
        <Route component={NotFound} />
      </Switch>
    </section>
  );
};
