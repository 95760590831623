import { keyframes } from '@emotion/react';
import { commonStyles } from 'styles';

const textColorMap = {
  primary: 'var(--white)',
  secondary: 'var(--primary)',
  default: 'var(--gray-100)',
  warning: 'var(--gray-900)',
  link: 'var(--gray-900)',
  info: 'var(--gray-300)',
};

const backgroundColorMap = {
  primary: 'var(--primary)',
  secondary: 'transparent',
  default: 'var(--gray-500)',
  danger: 'var(--error)',
  success: 'var(--success)',
  warning: 'var(--warning)',
  info: 'var(--info-light)',
  link: 'transparent',
};

const borderColorMap = {
  secondary: 'transparent',
  link: 'transparent',
  default: 'transparent',
};

const hoverBackgroundColorMap = {
  primary: 'var(--primary-dark)',
  secondary: 'transparent',
  default: 'var(--gray-500)',
  danger: 'var(--error-dark)',
  success: 'var(--success-light)',
  warning: 'var(--warning-light)',
  info: 'var(--info)',
  link: 'transparent',
};

const hoverBorderColorMap = {
  secondary: 'transparent',
  link: 'transparent',
};

export const buttonContainer = (props, isLoading) => {
  const {
    type = 'primary',
    disabled,
    color,
    borderColor,
    backColor,
    hoverBackColor,
    hoverBorderColor,
    fullWidth,
    small,
  } = props;
  const backgroundColor = backColor ? `var(--${backColor})` : backgroundColorMap[type] ?? 'var(--primary)';
  const hoverBackgroundColor = hoverBackColor ? `var(--${hoverBackColor})` : hoverBackgroundColorMap[type] ?? 'var(--primary-dark)';
  const textColor = color ? `var(--${color})` : textColorMap[type] ?? 'var(--secondary)';
  const borderColorDefault = borderColor ? `var(--${borderColor}` : borderColorMap[type] ?? backgroundColor;
  const borderColorHover = hoverBorderColor ? `var(--${hoverBorderColor})` : hoverBorderColorMap[type] ?? hoverBackgroundColor;
  const isLink = type === 'link';

  return [
    {
      cursor: disabled ? 'not-allowed' : 'pointer',
      padding: small ? '0.3em 0.8em' : '0.9em 1.6em',
      width: fullWidth ? '100%' : 'auto',
      minHeight: small ? '4.1rem' : '4.8rem',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 400,
      color: textColor,
      borderRadius: isLink ? 0 : 3,
      lineHeight: 1,
      border: `1px solid ${borderColorDefault}`,
      opacity: isLoading || disabled ? 0.4 : 1,
      backgroundColor,
      userSelect: 'none',
      whiteSpace: 'nowrap',
      transition:
        'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out, width .4s ease-in-out',

      '&:hover': {
        borderColor: borderColorHover,
        backgroundColor: hoverBackgroundColor,
      },

      '& > i': { marginRight: 6 },
    },
    (disabled || isLoading) && {
      '& *': { pointerEvents: 'none' },
    },
    ...commonStyles(props),
  ];
};

export const checkButtonContainer = (hasLabel, isChecked, props) => [
  {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: props.radio ? '50%' : 5,
    border: `${isChecked ? '3px' : '1px'} solid ${props.checkBorderColor ? `var(--${props.checkBorderColor})` : 'var(--gray-600)'}`,
    padding: 1,
    backgroundColor: isChecked
      ? 'var(--checkbox-active-bg)'
      : props.checkBgColor
      ? `var(--${props.checkBgColor})`
      : 'var(--checkbox-bg)',
    transition: 'all 0.4s ease-in-out',
    width: 20,
    height: 20,
  },


  hasLabel && {
    '& > .input-indicator': {
      marginRight: 8,
      flexShrink: 0,
    },
    '& > .label': {
      display: 'inline-flex',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      marginLeft: 8,
    },
  },
  ...commonStyles(props),
];

export const checkButtonIcon = (isChecked) => ({
  opacity: isChecked ? 1 : 0,
  transition: 'all 0.4s ease-in-out',
});

export const checkButtonContainerStyles = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  '& > .label': {
    display: 'inline-flex',
    alignItems: 'center',
    whiteSpace: 'normal',
    marginLeft: 8,
    fontSize: 13,
    fontWeight: 400,
    color: 'var(--secondary)',
  },
};

export const errorStyles = {
  color: 'var(--error)',
  fontSize: 11.5,
};

export const helpContent = {
  display: 'flex',
  alignItems: 'flex-start',
  marginTop: 8,
};

export const helpIcon = {
  marginRight: 3,
  width: 'auto',
  height: '1rem',
  maxHeight: '100%',
};

const switchButtonStartAnimation = (size) => keyframes`
  0% {
    right: ${size.padding}px;
    width: ${size.smallWidth};
  }
  25% {
    right: ${size.padding}px;
    width: calc(100% - ${size.padding * 2}px);
  }
  50% {
    right: auto;
    width: calc(100% - ${size.padding * 2}px);
    left: ${size.padding}px;
  }
  100% {
    left: ${size.padding}px;
    width: ${size.smallWidth};
  }
`;

const switchButtonEndAnimation = (size) => keyframes`
  0% {
    left: ${size.padding}px;
    width: ${size.smallWidth};
  }
  25% {
    left: ${size.padding}px;
    width: calc(100% - ${size.padding * 2}px);
  }
  50% {
    right: ${size.padding}px;
    width: calc(100% - ${size.padding * 2}px);
    left: auto;
  }
  100% {
    right: ${size.padding}px;
    width: ${size.smallWidth};
  }
`;

export const switchToggle = (size, color, isChecked) => ({
  cursor: 'pointer',
  width: size.width * 2,
  height: size.width,
  display: 'inline-flex',
  position: 'relative',
  backgroundColor: 'var(--theme-color)',
  textAlign: 'left',
  borderRadius: size.width,
  boxSizing: 'border-box',
  perspective: 300,
  border: `1px solid ${isChecked ? `var(--${color}, var(--primary))` : 'var(--gray-100)'}`,
  transition: 'border .8s ease-in-out',

  '&:before': {
    content: "''",
    width: size.smallWidth,
    height: size.smallWidth,
    position: 'absolute',
    right: size.padding,
    top: size.padding - 1,
    display: 'inline-flex',
    textAlign: 'center',
    borderRadius: size.smallWidth,
    transition: 'all 1s ease-in-out',
    transformOrigin: '0% 50%',
    transform: 'rotateY(0deg)',
    animation: `${
      isChecked ? switchButtonEndAnimation(size) : switchButtonStartAnimation(size)
    } 0.6s ease-in-out forwards`,
    backgroundColor: isChecked ? `var(--${color}, var(--primary))` : 'var(--gray-700)',
  },
});
