export const messageContainer = (isRight) => [
  {
    display: 'flex',
    flexDirection: isRight && 'row-reverse',

    '.action-buttons': {
      position: 'relative',
      '.more-icon': {
        cursor: 'pointer',
        color: 'var(--secondary)',
        margin: 2,
        '&:hover': {
          color: 'var(--gray-200)',
        },
      },
    },

    '.message-body': {
      backgroundColor: isRight ? 'var(--background-weak)' : 'var(--primary)',
      color: isRight ? 'var(--gray-100)' : 'var(--white)',
      borderRadius: isRight ? '8px 8px 0 8px' : '8px 8px 8px 0',
      padding: '15px 20px',
      minWidth: '12rem',
      position: 'relative',
      textAlign: 'left',

      '.message-tag': {
        fontSize: 9,
        position: 'absolute',
        top: 5,
        left: isRight ? 10 : 'auto',
        right: isRight ? 'auto' : 10,
        color: isRight ? 'var(--secondary-weaker)' : 'var(--primary-muted)',
        backgroundColor: 'transparent',
        border: 'none',
      },

      // '.message': {
      //   whiteSpace: 'pre-wrap',
      //   overflowWrap: 'anywhere',
      // },

      '.time-container': {
        color: isRight ? 'var(--secondary-weaker)' : 'var(--primary-muted)',
        textAlign: isRight ? 'left' : 'right',
        fontSize: 12,
        marginTop: 8,

        '.time-icon': {
          verticalAlign: 'middle',
        },

        span: {
          marginLeft: 3,
          verticalAlign: 'middle',
        },
      },
    },
  },
];

export const historyModalBody = {
  color: 'var(--secondary)',
  overflow: 'auto',
  maxHeight: '60vh',
  '.history-row': {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 8,
    width: '100%',
    padding: '1rem',
    borderBottom: '1px solid var(--border-color)',
    '&:first-of-type': {
      color: 'var(--gray-100)',
    },
    '&:last-child': {
      borderBottom: 'none',
    },
    'div.content': {
      cursor: 'pointer',
    },
    '.content': {
      flexBasis: '100%',
      '&.deleted': {
        fontSize: 12,
      },
    },
    '.date': {
      paddingTop: '0.4rem',
      fontSize: 10,
      flexBasis: '25%',
      textAlign: 'left',
      opacity: 0.7,
    },
    button: {
      backgroundColor: 'transparent',
      color: 'var(--secondary)',
    },
  },
};
