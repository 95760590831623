import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { Avatar, TextWithDotsAnimation } from 'components/shared';
import { ColorBatch, Icon } from 'components/ui';
import { getDateTimeLabel, getFirstSymbol, getUserDisplayName } from 'utils';
import { chatTypes } from 'enums';
import { getUserId, useMessagesStore } from 'stores';
import { badge, chatListItem } from './styles';

const ConversationListItem = (props) => {
  const { chat, isMember = true, noMessage, isSelected, onChatOpen } = props;
  const { messages } = useMessagesStore();
  const userId = getUserId();
  const { getText } = useTranslations();
  const [penpal, setPenpal] = useState({});

  useEffect(() => {
    // Set the other chat member if it is a direct chat.
    chat?.targetType === chatTypes.chat && setPenpal(chat?.users?.find((user) => user.id !== userId) ?? {});
  }, [chat]);

  const lastMessage = messages ? (messages[chat.id]?.messages ?? [])[0] : {};

  const handleOpenChat = () => onChatOpen(chat.id, chat.targetType);

  const getUnreadMessagesText = (count) => {
    if (count >= 1 && count <= 9) return `0${count}`;
    else if (count > 99) return '99+';

    return count;
  };

  return (
    <li key={chat?.id} role="presentation" css={chatListItem(isSelected, isMember)} onClick={handleOpenChat}>
      <div className="chat-info">
        {chat?.targetType === chatTypes.chat ? (
          <Avatar picThumbnailUrl={penpal?.pictureUrl} status={penpal?.status} />
        ) : chat?.icon ? (
          <Avatar picThumbnailUrl={chat?.icon} />
        ) : (
          <div className="letter-avatar">
            <div>{getFirstSymbol(chat?.name, chat?.targetType[0])}</div>
          </div>
        )}

        <div className="chat-body">
          <div className="chat-name">
            {chat?.targetType === chatTypes.channel &&
              (chat.users.find((member) => member.id === userId) ? (
                chat.public ? (
                  <Icon iconName="tag" className="name-icon" size={14} />
                ) : (
                  <Icon iconName="lock" className="name-icon" size={14} />
                )
              ) : (
                <Icon iconName="add" className="name-icon" size={14} />
              ))}
            <p>{chat?.name ?? getUserDisplayName(penpal)}</p>
          </div>
          {!noMessage && !!messages[chat.id]?.messages?.length && (
            <div className="last-message-container">
              {chat.isTyping ? (
                <TextWithDotsAnimation text={getText('typing')} />
              ) : (
                <>
                  {lastMessage?.image && !lastMessage?.deletedAt && <Icon className="image-message-icon" iconName="imagesmode" size={18} />}
                  {lastMessage?.file && !lastMessage?.deletedAt && <Icon className="image-message-icon" iconName="attach_file" size={18} />}
                  <p>{lastMessage?.deletedAt ? getText('deletedMessage') : lastMessage?.content}</p>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      {!noMessage && !!messages[chat.id]?.messages?.length && (
        <div className="chat-time">
          <div className="last-message-date">
            {lastMessage?.messageId && getDateTimeLabel(lastMessage.messageId, false, false)}
          </div>
          {messages[chat.id]?.unreadCount > 0 && (
            <ColorBatch withoutStartCase type="error" css={badge}>
              {getUnreadMessagesText(messages[chat.id].unreadCount)}
            </ColorBatch>
          )}
        </div>
      )}
    </li>
  );
};

ConversationListItem.propTypes = {
  chat: PropTypes.object,
  noMessage: PropTypes.bool,
  isMember: PropTypes.bool,
  isSelected: PropTypes.bool,
  onChatOpen: PropTypes.func,
};

export default ConversationListItem;
