import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { debounce } from 'lodash-es';
import { omit } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { searchInMessages } from 'services';
import { Avatar, Button, Icon, SearchBar, Spinner } from 'components';
import { getDateTimeLabel, getUserDisplayName } from 'utils';
import { searchContainer } from './styles';

const PAGE_SIZE = 10;

const MessagesSearch = (props) => {
  const { chat, onSelect } = props;
  const { getText } = useTranslations();
  const [pageIndex, setPageIndex] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const searchRef = useRef();
  const [hasNextPage, setHasNextPage] = useState(true);

  useEffect(() => {
    searchRef?.current?.focus();
  }, []);

  const handleSearchMessages = debounce((val) => {
    if (!val?.length) return setMessages([]);
    const data = {
      targetType: chat?.targetType,
      targetId: chat?.id,
      query: val,
      limit: PAGE_SIZE,
      offset: 0,
    };

    setIsLoading(true);
    setPageIndex(0);
    searchInMessages(data, (payload) => {
      setMessages(payload.messages ?? []);
      setHasNextPage(payload.totalResults > payload.messages?.length);
      setIsLoading(false);
    });
  }, 500);

  const handleLoadMore = () => {
    const data = {
      targetType: chat?.targetType,
      targetId: chat?.id,
      query: searchRef.current?.value,
      limit: PAGE_SIZE,
      offset: (pageIndex + 1) * PAGE_SIZE,
    };
    setPageIndex((prev) => prev + 1);
    setIsLoading(true);

    searchInMessages(data, (payload) => {
      payload.totalResults <= messages?.length + payload?.messages?.length && setHasNextPage(false);
      setMessages((prev) => prev.concat(payload.messages));
      setIsLoading(false);
      searchRef.current && searchRef.current.focus();
    });
  };

  const handleDelete = (options) => {
    options.inputProps.onDelete && options.inputProps.onDelete();
    setMessages([]);
  };

  return (
    <SearchBar
      css={searchContainer}
      placeholder="Search message"
      noDataText={getText('noMessagesFound')}
      options={messages}
      displayKey="content"
      laoding={isLoading}
      onInputChange={handleSearchMessages}
      renderSuggestionsContainer={(val) =>
        val?.isOpen && (
          <div {...{ css: val?.css }}>
            {val?.children}
            {!!val?.children?.length && hasNextPage && (
              <Button type="secondary" onClick={handleLoadMore}>
                {getText('loadMore')}
                <Spinner size={PAGE_SIZE} loading={isLoading} />
              </Button>
            )}
          </div>
        )
      }
      renderSuggestion={(val) => (
        <div
          className="suggestion"
          {...val?.itemProps}
          role={val?.itemProps?.role}
          onClick={() => {
            onSelect(val?.item);
            val.suggestionItemProps.onClick(val?.item);
          }}>
          <div className="message-row">
            <div className="message-meta">
              <div className="message-author">
                <Avatar picThumbnailUrl={val?.item?.author?.pictureUrl} status={val?.item?.author?.status} width={20} />
                <span>{getUserDisplayName(val?.item?.author)}</span>
                {val?.item?.deletedAt && <span className="message-tag">{getText('deleted')}</span>}
              </div>
              <div className="message-date">{getDateTimeLabel(val?.item?.messageId)}</div>
            </div>
            <span className="message-content">
              {val?.item?.content?.length >= 70 ? `${val?.item.content.slice(0, 70)}...` : val?.item?.content}
            </span>
          </div>
        </div>
      )}
      renderInput={(val) => (
        <div {...val.containerProps}>
          <label htmlFor={val.inputProps.id}>
            <Icon {...val.iconProps} onClick={val.inputProps.onFocus} />
          </label>
          <input {...omit(val.inputProps, ['onDelete'])} ref={searchRef} />
          <label htmlFor={val.inputProps.id}>
            <Spinner size={20} loading={isLoading} />
          </label>
          <label htmlFor={val.inputProps.id}>
            <Icon material size={20} iconName="close" css={val.iconProps.css} onClick={() => handleDelete(val)} />
          </label>
        </div>
      )}
    />
  );
};

MessagesSearch.propTypes = {
  chat: PropTypes.object,
  onSelect: PropTypes.func,
};

export default MessagesSearch;
