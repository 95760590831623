export const messageContainer = (isRight, isHighlighted) => ({
  display: 'flex',
  alignItems: 'flex-end',
  flexDirection: isRight ? 'row-reverse' : 'row',
  backgroundColor: isHighlighted ? 'var(--primary-weaker)' : 'transparent',
  transition: 'background-color 400ms linear',
  padding: '1.5rem 0',
  marginRight: !isRight ? '2.75rem' : 0,
  marginLeft: isRight ? '2.75rem' : 0,

  '.avatar-column': {
    minWidth: 50,
    maxWidth: 50,
    marginBottom: 0,
    textAlign: 'center',
  },
  '.message-column': {
    textAlign: isRight ? 'right' : 'left',
    position: 'relative',
    margin: 'auto 10px',

    '.message-box-item': {
      position: 'absolute',
      right: isRight && 0,
      borderStyle: 'solid',
      borderWidth: isRight ? '0 14px 14px 0' : '14px 14px 0 0',
      borderColor: isRight
        ? 'transparent var(--background-weak) transparent transparent'
        : 'var(--primary) transparent transparent transparent',
    },
    '.sender-name': {
      marginTop: 15,
      fontWeight: 500,
    },
  },
  '.deleted-message': {
    padding: '1rem',
    margin: '0.5rem 6rem',
    borderRadius: 8,
    border: '2px solid var(--background-weak)',
    color: 'var(--secondary)',
    background: 'transparent',
    fontSize: 12,
    cursor: 'pointer',
  },
});
