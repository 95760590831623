export const actionsContainer = (styles) => {
  const { top, right, bottom, background, color, width, ...rest } = styles;
  return [
    {
      zIndex: 19,
      overflow: 'auto',
      position: 'absolute',
      top: top ?? 60,
      right: right ?? 20,
      bottom: bottom ?? 'auto',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: background ? `var(--${background}, --background-strong)` : 'var(--background-strong)',
      border: '1px solid var(--border-color-weak)',
      color: color ? `var(--${color}, --secondary)` : 'var(--secondary)',
      fontSize: 14,
      borderRadius: '0.25rem',
      boxShadow: 'var(--box-shadow)',
      padding: '8px 0',
      width: width ?? 150,

      '.item': {
        padding: '8px 24px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        '&:hover': {
          backgroundColor: 'var(--hover-background)',
        },
      },
    },
    rest && { ...rest },
  ];
};
