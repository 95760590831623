export const channelsContainer = (withTabs) => ({
  position: 'relative',
  height: '100vh',
  overflow: 'hidden',
  padding: 12,
  backgroundColor: 'var(--background-stronger)',
  minWidth: 380,
  maxWidth: 380,

  '@media (max-width: 991px)': {
    minWidth: '100%',
    maxWidth: '100%',
  },

  '.page-title': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: 12,
  },

  '.tabs': {
    textAlign: 'right',
    '.tab-button': {
      '&:hover': {
        borderBottom: '1px solid var(--primary)',
        transition: 'border 400ms linear',
      },
      '&.active': {
        borderBottom: '1px solid var(--primary)'
      }
    },
  },

  '.channels-list': {
    marginTop: withTabs ? '1rem' : '5rem',

    ul: {
      overflow: 'overlay',
      maxHeight: 'calc(100vh - 185px)',
    },

    '.no-data': {
      padding: 18,
      textAlign: 'center',
      color: 'var(--secondary-weaker)',
      fontWeight: 500,
    },
  },
});
