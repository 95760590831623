export const chatInfoContainer = (statusColor) => [
  {
    position: 'absolute',
    top: 0,
    right: 0,
    backgroundColor: 'var(--theme-color)',
    width: '380px',
    height: '100vh',
    borderLeft: '5px solid var(--border-color)',
    zIndex: 29,

    '.page-title': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: 24,
    },

    '.name': {
      marginBottom: '0.25rem',
      fontSize: '1.5rem',
    },

    '.user-container': {
      padding: 24,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderBottom: '1px solid var(--border-color)',
      width: '100%',

      '.letter-avatar': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'var(--primary-weaker)',
        color: 'var(--primary)',
        borderRadius: '50%',
        border: '1px solid var(--border-color)',
        width: 86,
        height: 86,
        padding: '0.25rem',
        fontWeight: 500,
        marginBottom: 24,
        fontSize: 32,
      },
      '.name': {
        wordBreak: 'break-all',
      },
    },

    '.profile-description': {
      padding: 24,

      '.motto': {
        color: 'var(--secondary)',
        lineHeight: 1.5,
        fontSize: 15,
        marginBottom: 24,
      },

      '.user-content-container': {
        backgroundColor: 'var(--theme-color)',
        border: '1px solid var(--border-color-weak)',
        borderTop: 'none',

        '.user-content': {
          padding: '1.25rem 1.5rem',

          '.label': {
            color: 'var(--secondary-weaker)',
            marginBottom: 4,
          },
          '.name': {
            wordBreak: 'break-all',
          },
        },
      },

      '.attached-container': {
        backgroundColor: 'var(--theme-color)',
        border: '1px solid var(--border-color-weak)',
        borderTop: 'none',
        marginBottom: 4,
        padding: 16,
        height: 300,
        overflow: 'auto',

        '.scrollable-container::-webkit-scrollbar': {
          display: 'none',
        },

        '.file-content': {
          position: 'relative',
          border: '1px solid var(--border-color-weak)',
          padding: 8,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 4,
          borderRadius: 4,

          '.file-info-container': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',

            '.file-icon-container': {
              backgroundColor: 'var(--primary-weaker)',
              borderRadius: 4,
              width: 48,
              height: 48,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: 8,
            },

            '.size': {
              color: 'var(--secondary-weaker)',
            },

            '.name': {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '10rem',
            },
          },

          '.icon': {
            marginRight: 4,
          },
        },
      },

      '.members-container': {
        backgroundColor: 'var(--theme-color)',
        border: '1px solid var(--border-color)',
        borderTop: 'none',
        marginBottom: 4,
        padding: 16,
        height: 300,
        overflow: 'auto',

        '.scrollable-container::-webkit-scrollbar': {
          display: 'none',
        },

        '.member-content': {
          position: 'relative',
          border: '1px solid var(--border-color)',
          padding: 8,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginBottom: 4,
          borderRadius: 4,

          '.member-info-container': {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',

            '.member-image-container': {
              width: 48,
              height: 48,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginRight: 8,
            },

            '.size': {
              color: 'var(--secondary-weaker)',
            },

            '.name': {
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '18rem',
            },
          },

          '.icon': {
            marginRight: 4,
            color: 'var(--secondary-weaker)',
          },
        },
      },
    },
  },
  statusColor && {
    '.user-container': {
      '.status-container': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        '.status-indicator': {
          width: 9,
          height: 9,
          border: `3px solid var(--${statusColor}, ${statusColor})`,
          borderRadius: '50%',
          marginRight: 8,
        },

        '.status': {
          color: 'var(--secondary-weaker)',
        },
      },
    },
  },
];
