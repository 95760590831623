export const searchContainer = {
  position: 'absolute',
  right: 0,
  width: '300px',
  '.suggestion': {
    borderTop: '1px solid var(--active-background)',
    '&:hover': {
      backgroundColor: 'var(--active-background)',
    },
    '.message-row': {
      width: '100%',
      '.message-meta': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: 12,
        marginBottom: 10,
        color: 'var(--gray-200)',
        '.message-author': {
          display: 'flex',
          alignItems: 'center',
          gap: 10,
        },
        '.message-tag': {
          color: 'var(--error)',
          fontSize: 10,
        },
        '.message-date': {
          color: 'var(--secondary)',
          fontSize: 10,
          opacity: 0.7,
          marginRight: 5,
        },
      },
    },
  },
};
